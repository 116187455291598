import React, { useState , useEffect} from 'react';
import "./Subscriptions.css";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import SubscriptionModal from './SubscriptionModal';
import NumberTranslator from "../../../Translation/NumberTranslator";
import SyncLoader from "react-spinners/SyncLoader"; // استيراد الـ Spinner
import { useTranslation } from 'react-i18next';
const Subscriptions = () => {
  const { t } = useTranslation('subscriptions'); // تحديد Namespace
  const [showDetails, setShowDetails] = useState(false);
  const [detailsContent, setDetailsContent] = useState('');
  const [messageLevel1, setMessageLevel1] = useState(null);
  const [messageLevel2, setMessageLevel2] = useState('');
  const [messageLevel3, setMessageLevel3] = useState('');
  const [messageLevel4, setMessageLevel4] = useState('');
  const [messageLevel5, setMessageLevel5] = useState('');
  const [teamStats, setTeamStats] = useState(null);  // لتخزين البيانات
  const [loading, setLoading] = useState(true);       // لتتبع حالة التحميل
  const [error, setError] = useState(null);    
  const [imageSrc, setImageSrc] = useState(null);
  const [userLevels, setUserLevels] = useState(null);
  const [statusAccept, setStatusAccept] = useState(0);
  const isLoggedIn = Boolean(localStorage.getItem('token'));
  

  const SubscriptionModal = ({ message, onClose }) => (
  <div className="modal-overlay">
    <div className="modal-content">
      <p>{message}</p>
      <button onClick={onClose}>{t('done')}</button>
    </div>
  </div>
);
  useEffect(() => {
      const fetchStatus = async () => {
        if (isLoggedIn) {
          const token = localStorage.getItem('token');
          try {
            const response = await axios.get('http://sanaddigital.com/public/api/teamStats', {
              headers: { Authorization: `Bearer ${token}` },
            });
           
            setTeamStats(response.data);
            setStatusAccept(response.data.user.status_accept);
            const baseURL = 'http://sanaddigital.com/storage/app/public/'; // ضع رابط API الأساسي هنا
              setImageSrc(baseURL + response.data.user.image);
      
            // استخراج المستويات المشترك فيها المستخدم من levels_user
            const subscribedLevels = response.data.levels_user.map(level => level.level);
            setUserLevels(subscribedLevels);
          } catch (error) {
            setError(error);
          }
          finally {
            setLoading(false); // إيقاف حالة التحميل بعد إكمال العملية
        }
        
        }else {
          setLoading(false); // إيقاف التحميل إذا لم يكن المستخدم مسجلًا
      }
      };
  
      fetchStatus(); // جلب البيانات عند تحميل المكون لأول مرة
 
    // إضافة setInterval لتحديث البيانات كل 4 ثوانٍ
    const intervalId = setInterval(() => {
      fetchStatus(); // استدعاء fetchData مباشرة هنا
    }, 8000);

    // تنظيف الـ interval عند إزالة المكون
    return () => clearInterval(intervalId);
    
  
  }, [isLoggedIn]);
  



  const copyReferralLink = () => {
    const copyText = document.querySelector('.member-info input');
    if (copyText) {
      copyText.select();
      document.execCommand('copy');
      alert('Invitation link copied');
    } else {
      alert('No input field found to copy the link.');
    }
  };

  const openDetails = (content) => {
    setDetailsContent(content);
    setShowDetails(true);
  };

  const closeDetails = () => {
    setShowDetails(false);
    setDetailsContent('');
  };

  const token = localStorage.getItem("token"); // احصل على التوكن من التخزين المحلي

  const handleLevelSubscription = async (level, setMessage) => {
    if (isLoggedIn && statusAccept === 1) {

    try {
      const response = await axios.post(`http://sanaddigital.com/public/api/level${level}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}` // أضف التوكن في ترويسة الطلب
        }
      });
      setMessage(response.data.message);
         // تحديث حالة userLevels لإظهار التغييرات فورًا
    setUserLevels((prevLevels) => [...prevLevels, level]);
    } catch (error) {
      if (error.response) {
        console.error('Error:', error.response.data);
        setMessage('Failed to subscribe: ' + error.response.data.message);
      } else {
        console.error('Error:', error.message);
        setMessage('Failed to subscribe: ' + error.message);
      }
    }
  }};

  const level1 = (e) => {
    e.preventDefault();
    handleLevelSubscription(1, setMessageLevel1);

  };
 

  const level2 = (e) => {
    e.preventDefault();
    handleLevelSubscription(2, setMessageLevel2);
  };

  const level3 = (e) => {
    e.preventDefault();
    handleLevelSubscription(3, setMessageLevel3);
  };

  const level4 = (e) => {
    e.preventDefault();
    handleLevelSubscription(4, setMessageLevel4,);
  };

  const level5 = (e) => {
    e.preventDefault();
    handleLevelSubscription(5, setMessageLevel5);
  };
  const handleCloseModal = () => {
    setMessageLevel1(null);
    setMessageLevel2(null);
    setMessageLevel3(null);
    setMessageLevel4(null);
    setMessageLevel5(null);
  };
  
  const isUserSubscribedToLevel = (userId, level) => {
    if (isLoggedIn && statusAccept === 1) {
    if (!teamStats || !teamStats.level_Team) return false; // تأكد من وجود البيانات

    const levelUsers = teamStats.level_Team[`level${level}`] || [];
    return levelUsers.some(member => member.user_id === userId);
  }};
  // دالة لتحديد اللون وظهور الزر لكل مستوى
// دالة getLevelStatus مع التعديلات المطلوبة
const getLevelStatus = (level) => {
  if (userLevels.includes(level)) {
    return { color: "green", showCheckIcon: true, showButton: false };
  } else if (userLevels.length === 0 && level === 1) {
    // إذا لم يكن مشتركًا بأي مستوى، يظهر المستوى الأول باللون الأصفر
    return { color: "yellow", showCheckIcon: false, showButton: true, disabled: false };
  } else if (userLevels.includes(level - 1)) {
    return { color: "yellow", showCheckIcon: false, showButton: true, disabled: false };
  } else {
    return { color: "red", showCheckIcon: false, showButton: true, disabled: true };
  }
};

  return (
    <div>
       {loading ? (
                <div className="loader-container">
                    <SyncLoader color="#36d7b7" loading={loading} size={30} />
                </div>
            ) : (
        <div>
       
      {isLoggedIn && statusAccept === 1 ?  <div className="container">
        <aside>
      <div className="member-info">
        <img
          src={imageSrc}
          alt={t("profile_image_alt")}
          style={{ width: "160px", height: "150px", borderRadius: "50%" }}
        />
        <h4>{teamStats.user.name}</h4>
        <p style={{color: '#32A8A2'}}>{t("invitation_link")}</p>
        <input type="text" value={teamStats.user.invitation_link} readOnly />
        <button onClick={copyReferralLink}>{t("copy_link_button")}</button>
      </div>
      <div dir={t('direction')} className="levels">
        <h4>{t("levels_title")}</h4>
        <ul>
          <li
            style={{ width: "30%" }}
            className={`level-card ${getLevelStatus(1).color}`}
            key={1}
          >
            <p>{t("level_1")}</p>
            <p>{t("level_1_usdt")}</p>
          </li>
          <li
            style={{ width: "45%" }}
            className={`level-card ${getLevelStatus(2).color}`}
            key={2}
          >
            <p>{t("level_2")}</p>
            <p>{t("level_2_usdt")}</p>
          </li>
          <li
            style={{ width: "60%" }}
            className={`level-card ${getLevelStatus(3).color}`}
            key={3}
          >
            <p>{t("level_3")}</p>
            <p>{t("level_3_usdt")}</p>
          </li>
          <li
            style={{ width: "75%" }}
            className={`level-card ${getLevelStatus(4).color}`}
            key={4}
          >
            <p>{t("level_4")}</p>
            <p>{t("level_4_usdt")}</p>
          </li>
          <li
            style={{ width: "100%" }}
            className={`level-card ${getLevelStatus(5).color}`}
            key={5}
          >
            <p>{t("level_5")}</p>
            <p>{t("level_5_usdt")}</p>
          </li>
        </ul>
      </div>
      <main>
        <div dir={t('direction')}>
          <h1>{t("subscription_levels_title")}</h1>
        <p>{t("subscription_description")}</p>
        </div>
        

        <div dir={t('direction')} className="levels-grid">
          <div  className={`level-card ${getLevelStatus(1).color}`} key={1}>
            <h2>{t("level_1")}</h2>
            <p>{t("level_1_price")}</p>
            <div className="buttondiv">
              {getLevelStatus(1).showCheckIcon ? (
                <i className="fa-regular fa-circle-check"></i>
              ) : (
                <form onSubmit={level1}>
                  <button disabled={getLevelStatus(1).disabled}>
                    {t("subscribe_button")}
                  </button>
                </form>
              )}
              <button
                onClick={() =>
                  openDetails(
                    <div className="modal-body">
                      <p>{t("modal_level_1_step_1")}</p>
                      <p>{t("modal_level_1_step_2")}</p>
                      <p>{t("modal_level_1_step_3")}</p>
                      <p>{t("modal_level_1_step_4")}</p>
                    </div>
                  )
                }
              >
                {t("view_details_button")}
              </button>
            </div>
          </div>
 
          <div className={`level-card ${getLevelStatus(2).color}`}>
        <h2>{t("level_2_title")}</h2>
        <p>{t("level_2_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(2).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level2}>
              <button disabled={getLevelStatus(2).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_2_precondition")}</p>
                  <p>{t("level_2_instruction")}</p>
                  <p>{t("level_2_reward_upgrade")}</p>
                  <p>{t("level_2_rewards_subscriptions")}</p>
                  <p>{t("level_2_invite_reward")}</p>
                  <p>{t("level_2_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>

      <div className={`level-card ${getLevelStatus(3).color}`}>
        <h2>{t("level_3_title")}</h2>
        <p>{t("level_3_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(3).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level3}>
              <button disabled={getLevelStatus(3).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_3_precondition")}</p>
                  <p>{t("level_3_instruction")}</p>
                  <p>{t("level_3_reward_upgrade")}</p>
                  <p>{t("level_3_rewards_subscriptions")}</p>
                  <p>{t("level_3_invite_reward")}</p>
                  <p>{t("level_3_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>

      <div className={`level-card ${getLevelStatus(4).color}`}>
        <h2>{t("level_4_title")}</h2>
        <p>{t("level_4_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(4).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level4}>
              <button disabled={getLevelStatus(4).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_4_precondition")}</p>
                  <p>{t("level_4_instruction")}</p>
                  <p>{t("level_4_reward_upgrade")}</p>
                  <p>{t("level_4_rewards_subscriptions")}</p>
                  <p>{t("level_4_invite_reward")}</p>
                  <p>{t("level_4_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>

      <div className={`level-card ${getLevelStatus(5).color}`}>
        <h2>{t("level_5_title")}</h2>
        <p>{t("level_5_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(5).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level5}>
              <button disabled={getLevelStatus(5).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_5_precondition")}</p>
                  <p>{t("level_5_instruction")}</p>
                  <p>{t("level_5_reward_upgrade")}</p>
                  <p>{t("level_5_rewards_subscriptions")}</p>
                  <p>{t("level_5_invite_reward")}</p>
                  <p>{t("level_5_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>
    </div>

       </main>
         <div className="referrals">
           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px' }}>
             <h4 style={{ marginTop: '13px' }}>{t('team_members')}</h4>
             
             <NavLink to="/AllMember" style={{textDecoration: "none", marginTop: "0px"}}>
             
               <button style={{padding: '0px', marginTop: '10px'}} className="back-button">
               {t('view_all')}
                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     className="feather feather-arrow-right">
                     <line x1="5" y1="12" x2="19" y2="12"></line>
                     <polyline points="12 5 19 12 12 19"></polyline>
                 </svg>
               </button>
             </NavLink>
           </div>
           <ul style={{ marginTop: '0px' }}>
       <div style={{ display: 'flex', justifyContent: 'end', fontSize: "17px" }}>
         <p style={{ paddingLeft: '15px', marginBottom: '3px' }}>1</p>
         <p style={{ paddingLeft: '15px', marginBottom: '3px' }}>2</p>
         <p style={{ paddingLeft: '14px', marginBottom: '3px' }}>3</p>
         <p style={{ paddingLeft: '16px', marginBottom: '3px' }}>4</p>
         <p style={{ paddingLeft: '9px', marginBottom: '3px', paddingRight: "10px" }}>5</p>
       </div>
       {teamStats.users.slice(0, 5).map((user, index) => (
   <li key={user.id}>
     <p style={{ 
  wordWrap: "break-word", 
  overflow: "hidden", 
  textOverflow: "ellipsis", 
  whiteSpace: "nowrap", 
  maxWidth: "200px" // أو حسب العرض المطلوب
}}>{user.name}</p>
     <div className="member-status" style={{ alignItems: "flex-end" }}>
                 {[1, 2, 3, 4, 5].map(level => (
                     <span
                       key={level}
                       className={`dot ${isUserSubscribedToLevel(user.id, level) ? 'active2' : 'deleted'}`}
                     ></span>
                   ))}
                 </div>
   </li>
 ))}
 
     </ul>
 
         </div>
         <div dir={t('direction')} className="sidebar">
      <div className="team-stats">
        <h2>{t('sidebar_team_stats')}</h2>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_total_team')}</p>
          <div className="stat-circle">
            <NumberTranslator id="total-team" number={teamStats.total_count} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_1_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-1" number={teamStats.level_Count.level1} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_2_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-2" number={teamStats.level_Count.level2} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_3_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-3" number={teamStats.level_Count.level3} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_4_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-4" number={teamStats.level_Count.level4} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_5_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-5" number={teamStats.level_Count.level5} />
          </div>
        </div>
      </div>
      </div>

      <div className="earnings">
        <h4>{t('sidebar_team_rewards')}</h4>
        <div style={{ fontSize: '24px' }}>
  <NumberTranslator number={0} />
  $
</div>

      </div>
       </aside>
       <main>
         
       <div dir={t('direction')}>
          <h1>{t("subscription_levels_title")}</h1>
        <p>{t("subscription_description")}</p>
        </div>
        

         <div dir="rtl" className="levels-grid">
          <div className={`level-card ${getLevelStatus(1).color}`} key={1}>
            <h2>{t("level_1")}</h2>
            <p>{t("level_1_price")}</p>
            <div className="buttondiv">
              {getLevelStatus(1).showCheckIcon ? (
                <i className="fa-regular fa-circle-check"></i>
              ) : (
                <form onSubmit={level1}>
                  <button disabled={getLevelStatus(1).disabled}>
                    {t("subscribe_button")}
                  </button>
                </form>
              )}
              <button
                onClick={() =>
                  openDetails(
                    <div className="modal-body">
                      <p>{t("modal_level_1_step_1")}</p>
                      <p>{t("modal_level_1_step_2")}</p>
                      <p>{t("modal_level_1_step_3")}</p>
                      <p>{t("modal_level_1_step_4")}</p>
                    </div>
                  )
                }
              >
                {t("view_details_button")}
              </button>
            </div>
          </div>
 
          <div className={`level-card ${getLevelStatus(2).color}`}>
        <h2>{t("level_2_title")}</h2>
        <p>{t("level_2_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(2).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level2}>
              <button disabled={getLevelStatus(2).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_2_precondition")}</p>
                  <p>{t("level_2_instruction")}</p>
                  <p>{t("level_2_reward_upgrade")}</p>
                  <p>{t("level_2_rewards_subscriptions")}</p>
                  <p>{t("level_2_invite_reward")}</p>
                  <p>{t("level_2_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>

      <div className={`level-card ${getLevelStatus(3).color}`}>
        <h2>{t("level_3_title")}</h2>
        <p>{t("level_3_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(3).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level3}>
              <button disabled={getLevelStatus(3).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_3_precondition")}</p>
                  <p>{t("level_3_instruction")}</p>
                  <p>{t("level_3_reward_upgrade")}</p>
                  <p>{t("level_3_rewards_subscriptions")}</p>
                  <p>{t("level_3_invite_reward")}</p>
                  <p>{t("level_3_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>

      <div className={`level-card ${getLevelStatus(4).color}`}>
        <h2>{t("level_4_title")}</h2>
        <p>{t("level_4_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(4).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level4}>
              <button disabled={getLevelStatus(4).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_4_precondition")}</p>
                  <p>{t("level_4_instruction")}</p>
                  <p>{t("level_4_reward_upgrade")}</p>
                  <p>{t("level_4_rewards_subscriptions")}</p>
                  <p>{t("level_4_invite_reward")}</p>
                  <p>{t("level_4_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>

      <div className={`level-card ${getLevelStatus(5).color}`}>
        <h2>{t("level_5_title")}</h2>
        <p>{t("level_5_price")}</p>
        <div className="buttondiv">
          {getLevelStatus(5).showCheckIcon ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <form onSubmit={level5}>
              <button disabled={getLevelStatus(5).disabled}>
                {t("subscribe_button")}
              </button>
            </form>
          )}
          <button
            onClick={() =>
              openDetails(
                <div className="modal-body">
                  <p>{t("level_5_precondition")}</p>
                  <p>{t("level_5_instruction")}</p>
                  <p>{t("level_5_reward_upgrade")}</p>
                  <p>{t("level_5_rewards_subscriptions")}</p>
                  <p>{t("level_5_invite_reward")}</p>
                  <p>{t("level_5_members_reward")}</p>
                </div>
              )
            }
          >
            {t("view_details_button")}
          </button>
        </div>
      </div>
    </div>
        
 {messageLevel1 && <SubscriptionModal message={messageLevel1} onClose={handleCloseModal} />}
 {messageLevel2 && <SubscriptionModal message={messageLevel2} onClose={handleCloseModal} />}
 {messageLevel3 && <SubscriptionModal message={messageLevel3} onClose={handleCloseModal} />}
 {messageLevel4 && <SubscriptionModal message={messageLevel4} onClose={handleCloseModal} />}
 {messageLevel5 && <SubscriptionModal message={messageLevel5} onClose={handleCloseModal} />}
 
       </main>
       {showDetails && (
           <div dir={t('direction')} className="details-modal">
             <div className="details-content">
               <button className="close-button" onClick={closeDetails}>&times;</button>
               <h3 style={{color: "#106C65"}}>{t("subscription_details")}</h3>
               <p>{detailsContent}</p>
               <div style={{marginLeft: "0px"}} className="btn-wrapper padding-top-20" onClick={closeDetails}>
                       <a style={{ textDecoration: "none" }}  className="boxed-btn btn-work">{t('done')}</a>
                     </div>
               
             </div>
           </div>
         )}
     </div>  
        : <div className="container">
      <aside>
      <div className="member-info">
      <img src='/images/prof.jpg' alt="Profile" style={{ width: '160px', height: '150px', borderRadius: '50%' }} />
        <h4>Member Name</h4>
         <p style={{color: '#32A8A2'}}>{t("invitation_link")}</p>
        <input type="text" value='invitation link' readOnly />
        <button >{t("copy_link_button")}</button>
      </div>
        <div dir={t('direction')} className="levels">
        <h4>{t("levels_title")}</h4>
        <ul>
          <li
            style={{ width: "30%" }}
            className="locked"
           
          >
            <p>{t("level_1")}</p>
            <p>{t("level_1_usdt")}</p>
          </li>
          <li
            style={{ width: "45%" }}
            className="locked"
            
          >
            <p>{t("level_2")}</p>
            <p>{t("level_2_usdt")}</p>
          </li>
          <li
            style={{ width: "60%" }}
            className="locked"
           
          >
            <p>{t("level_3")}</p>
            <p>{t("level_3_usdt")}</p>
          </li>
          <li
            style={{ width: "75%" }}
            className="locked"
            
          >
            <p>{t("level_4")}</p>
            <p>{t("level_4_usdt")}</p>
          </li>
          <li
            style={{ width: "100%" }}
            className="locked"
            
          >
            <p>{t("level_5")}</p>
            <p>{t("level_5_usdt")}</p>
          </li>
        </ul>
      </div>
        <main>
        <div dir={t('direction')}>
          <h1>{t("subscription_levels_title")}</h1>
        <p>{t("subscription_description")}</p>
        </div>
        <div dir={t('direction')} className="levels-grid">
  <div className="level-card locked" >
    <h2>{t("level_1")}</h2>
    <p>{t("level_1_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("modal_level_1_step_1")}</p>
              <p>{t("modal_level_1_step_2")}</p>
              <p>{t("modal_level_1_step_3")}</p>
              <p>{t("modal_level_1_step_4")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_2_title")}</h2>
    <p>{t("level_2_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_2_precondition")}</p>
              <p>{t("level_2_instruction")}</p>
              <p>{t("level_2_reward_upgrade")}</p>
              <p>{t("level_2_rewards_subscriptions")}</p>
              <p>{t("level_2_invite_reward")}</p>
              <p>{t("level_2_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_3_title")}</h2>
    <p>{t("level_3_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_3_precondition")}</p>
              <p>{t("level_3_instruction")}</p>
              <p>{t("level_3_reward_upgrade")}</p>
              <p>{t("level_3_rewards_subscriptions")}</p>
              <p>{t("level_3_invite_reward")}</p>
              <p>{t("level_3_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_4_title")}</h2>
    <p>{t("level_4_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_4_precondition")}</p>
              <p>{t("level_4_instruction")}</p>
              <p>{t("level_4_reward_upgrade")}</p>
              <p>{t("level_4_rewards_subscriptions")}</p>
              <p>{t("level_4_invite_reward")}</p>
              <p>{t("level_4_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_5_title")}</h2>
    <p>{t("level_5_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_5_precondition")}</p>
              <p>{t("level_5_instruction")}</p>
              <p>{t("level_5_reward_upgrade")}</p>
              <p>{t("level_5_rewards_subscriptions")}</p>
              <p>{t("level_5_invite_reward")}</p>
              <p>{t("level_5_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>
</div>
      </main>
        <div className="referrals">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px' }}>
             <h4 style={{ marginTop: '13px' }}>{t('team_members')}</h4>
             
             <NavLink  style={{textDecoration: "none", marginTop: "0px"}}>
             
               <button style={{padding: '0px', marginTop: '10px'}} className="back-button">
               {t('view_all')}
                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     className="feather feather-arrow-right">
                     <line x1="5" y1="12" x2="19" y2="12"></line>
                     <polyline points="12 5 19 12 12 19"></polyline>
                 </svg>
               </button>
             </NavLink>
           </div>
          <ul style={{ marginTop: '0px' }}>
            <div style={{ display: 'flex', justifyContent: 'end', fontSize: "16px" }}>
              <p style={{ paddingLeft: '13px', marginBottom: '3px' }}>1</p>
              <p style={{ paddingLeft: '10px', marginBottom: '3px' }}>2</p>
              <p style={{ paddingLeft: '10px', marginBottom: '3px' }}>3</p>
              <p style={{ paddingLeft: '10px', marginBottom: '3px' }}>4</p>
              <p style={{ paddingLeft: '9px', marginBottom: '3px', paddingRight: "10px" }}>5</p>
            </div>
            <li>
              <p>Member 1</p>
              <div>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
              </div>
            </li>
            <li>
              <p>Member 2</p>
              <div>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
              </div>
            </li>
            <li>
              <p>Member 3</p>
              <div>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
              </div>
            </li>
            <li>
              <p>Member 4</p>
              <div>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
              </div>
            </li>
            <li>
              <p>Member 5</p>
              <div>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
                <i style={{ color: 'brown' }} className="fa-solid fa-circle"></i>
              </div>
            </li>
          </ul>

        </div>
        <div dir={t('direction')} className="sidebar">
      <div className="team-stats">
        <h2>{t('sidebar_team_stats')}</h2>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_total_team')}</p>
          <div className="stat-circle">
            <NumberTranslator id="total-team" number={0} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_1_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-1" number={0} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_2_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-2" number={0} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_3_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-3" number={0} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_4_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-4" number={0} />
          </div>
        </div>
        <div className="stat-item">
          <p className="stat-label">{t('sidebar_level_5_subscribers')}</p>
          <div className="stat-circle">
            <NumberTranslator id="level-5" number={0} />
          </div>
        </div>
      </div>
      </div>

      <div className="earnings">
        <h4>{t('sidebar_team_rewards')}</h4>
        <div style={{ fontSize: '24px' }}>
  <NumberTranslator number={0} />
  $
</div>

      </div>
      </aside>
      <main>
      <div dir={t('direction')}>
          <h1>{t("subscription_levels_title")}</h1>
        <p>{t("subscription_description")}</p>
        </div>
        <div dir={t('direction')} className="levels-grid">
  <div className="level-card locked" >
    <h2>{t("level_1")}</h2>
    <p>{t("level_1_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("modal_level_1_step_1")}</p>
              <p>{t("modal_level_1_step_2")}</p>
              <p>{t("modal_level_1_step_3")}</p>
              <p>{t("modal_level_1_step_4")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_2_title")}</h2>
    <p>{t("level_2_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_2_precondition")}</p>
              <p>{t("level_2_instruction")}</p>
              <p>{t("level_2_reward_upgrade")}</p>
              <p>{t("level_2_rewards_subscriptions")}</p>
              <p>{t("level_2_invite_reward")}</p>
              <p>{t("level_2_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_3_title")}</h2>
    <p>{t("level_3_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_3_precondition")}</p>
              <p>{t("level_3_instruction")}</p>
              <p>{t("level_3_reward_upgrade")}</p>
              <p>{t("level_3_rewards_subscriptions")}</p>
              <p>{t("level_3_invite_reward")}</p>
              <p>{t("level_3_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_4_title")}</h2>
    <p>{t("level_4_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_4_precondition")}</p>
              <p>{t("level_4_instruction")}</p>
              <p>{t("level_4_reward_upgrade")}</p>
              <p>{t("level_4_rewards_subscriptions")}</p>
              <p>{t("level_4_invite_reward")}</p>
              <p>{t("level_4_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>

  <div className="level-card locked">
    <h2>{t("level_5_title")}</h2>
    <p>{t("level_5_price")}</p>
    <div className="buttondiv">
      <button disabled>{t("subscribe_button")}</button>
      <button
        onClick={() =>
          openDetails(
            <div className="modal-body">
              <p>{t("level_5_precondition")}</p>
              <p>{t("level_5_instruction")}</p>
              <p>{t("level_5_reward_upgrade")}</p>
              <p>{t("level_5_rewards_subscriptions")}</p>
              <p>{t("level_5_invite_reward")}</p>
              <p>{t("level_5_members_reward")}</p>
            </div>
          )
        }
      >
        {t("view_details_button")}
      </button>
    </div>
  </div>
</div>
      </main>
      {showDetails && (
           <div dir={t('direction')} className="details-modal">
             <div className="details-content">
               <button style={{fontSize: '25px', color: 'black'}} className="close-button" onClick={closeDetails}>&times;</button>
               <h3 style={{color: "#106C65"}}>{t("subscription_details")}</h3>
               <p>{detailsContent}</p>
               <div style={{marginLeft: "0px", color: 'black'}} className="btn-wrapper padding-top-20" onClick={closeDetails}>
                       <a style={{ textDecoration: "none" }}  className="boxed-btn btn-work">{t('done')}</a>
                     </div>
               
             </div>
           </div>
         )}
    </div>
       
    }
  
    </div>
    )}
    </div>
  
   
  );
};

export default Subscriptions;
