import React, { useEffect, useState } from 'react';
import './SanadStatistics.css'; // Assuming you have a separate CSS file for styling
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
const SanadStatistics = () => {
  const [statistics, setStatistics] = useState({}); // تعريف حالة للإحصائيات
  const [error, setError] = useState(null); // تعريف حالة للخطأ
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من إذا كان أدمن
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
    const fetchData = async () => {
      try {
        const response = await fetch('http://sanaddigital.com/public/api/sanadStats', {
          headers: {
            Authorization: `Bearer ${token}`, // إضافة التوكن في الهيدر
          },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setStatistics(data);
       
      } catch (err) {
        console.error('Error:', err);
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  const goBack = () => {
    window.history.back(); // للرجوع إلى الصفحة السابقة
  };
  if (!isAdmin) {
    return null; // منع عرض الداشبورد إذا لم يكن أدمن
  }
  return (
    <div style={{marginTop: '-65px'}} className="statistics-container">
      <div className="back-arrow" onClick={goBack}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <h1 className="statistics-title">Sanad Statistics</h1>
      
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="statistics-cards">
          <div style={{ background: "#32CD32" }} className="statistics-card card-gradient">
            <h2>Total Team Members</h2>
            <p>{statistics.totalTeamCount || 0}</p>
          </div>
          <div style={{ background: "#32CD32" }} className="statistics-card card-gradient">
            <h2>Verified Members</h2>
            <p>{statistics.verifiedMembersCount || 0}</p>
          </div>
          <div className="statistics-card card-gradient">
            <h2>Level 1 Members</h2>
            <p>{statistics.membersInLevel1Counts || 0}</p>
          </div>
          <div className="statistics-card card-gradient">
            <h2>Level 2 Members</h2>
            <p>{statistics.membersInLevel2Counts || 0}</p>
          </div>
          <div className="statistics-card card-gradient">
            <h2>Level 3 Members</h2>
            <p>{statistics.membersInLevel3Counts || 0}</p>
          </div>
          <div className="statistics-card card-gradient">
            <h2>Level 4 Members</h2>
            <p>{statistics.membersInLevel4Counts || 0}</p>
          </div>
          <div className="statistics-card card-gradient">
            <h2>Level 5 Members</h2>
            <p>{statistics.membersInLevel5Counts || 0}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SanadStatistics;
