import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SignUpMembers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const SignUpMembers = () => {
  const [members, setMembers] = useState([]);
  const navigate = useNavigate(); // تعريف navigate

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول إذا لم يكن هناك توكن
      return;
    }

    // التحقق مما إذا كان المستخدم أدمن
    const checkAdmin = async () => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/checkAdmin', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.data.is_admin) {
          navigate('/404'); // توجيه المستخدم غير الأدمين إلى صفحة 404
        }
      } catch (error) {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه إلى صفحة 404 في حال حدوث خطأ
      }
    };

    const fetchMembers = async () => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/signupMembers', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setMembers(response.data.signupMembers);
      } catch (error) {
        console.error('Error fetching members:', error.response ? error.response.data : error.message);
      }
    };

    checkAdmin(); // التحقق من حالة الأدمين
    fetchMembers(); // جلب بيانات الأعضاء
  }, [navigate]);

  const deleteMember = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`http://sanaddigital.com/public/api/deleteSignupMember/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setMembers(members.filter((member) => member.id !== id));
      } else {
        console.error('Failed to delete member:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting member:', error.response ? error.response.data : error.message);
    }
  };

  const sortedMembers = members.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  const goBack = () => {
    window.history.back();
  };

  return (
    <div style={{marginTop: '-65px'}} className="signup-members-page">
      <div className="back-arrow" onClick={goBack}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <h2>Sign Up Members</h2>
      <div className="members-list">
        {sortedMembers.map((member) => (
          <div key={member.id} className="member-card">
            <div className="member-info">
              <span className="info-label">Name:</span> {member.name}
            </div>
            <div className="member-info">
              <span className="info-label">ID:</span> {member.id}
            </div>
            <div className="member-info">
              <span className="info-label">Registration Date:</span> {new Date(member.created_at).toLocaleDateString()}
            </div>
            <div className="member-actions">
              <button className="delete-button" onClick={() => deleteMember(member.id)}>
                <FontAwesomeIcon icon={faTrash} /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignUpMembers;
