import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Payout.css';
import { useNavigate, NavLink } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import Nav from '../../Nav/Nav';
import { useTranslation } from 'react-i18next';
function WithdrawalForm() {
    const { t } = useTranslation('wallet'); // تحديد Namespace
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [totalAfterFee, setTotalAfterFee] = useState('0.00');
    const [showModal, setShowModal] = useState(false);
    const [errors, setErrors] = useState({}); 
    const [generalError, setGeneralError] = useState(''); 
    const [balance, setBalance] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        // Check if user is authenticated
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/404'); // Redirect to login page if not authenticated
          return;
        }

        // Fetch current balance from the API
        axios.get('http://sanaddigital.com/public/api/user/current-balance', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => {
            setBalance(response.data.data || 0); // Set the balance, ensure it's a number
          })
          .catch(error => {
            console.error('Error fetching balance:', error);
          });
    
       
      }, [navigate]);
    const handleAmountChange = (event) => {
        const value = event.target.value;
        setAmount(value);

        const amountValue = parseFloat(value);
        const fee = 0.3;
        if (!isNaN(amountValue)) {
            const total = (amountValue - fee).toFixed(2);
            setTotalAfterFee(total);
        } else {
            setTotalAfterFee('0.00');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (address && amount) {
            const token = localStorage.getItem('token'); 
            if (!token) {
                alert('You need to log in to proceed.');
                return;
            }

            axios.post('http://sanaddigital.com/public/api/user/payout', 
                { address, amount: parseFloat(amount) },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then(response => {
               
                if (response.data.message) {
                    setShowModal(true); 
                    setAddress(''); 
                    setAmount(''); 
                    setTotalAfterFee('0.00'); 
                    setErrors({}); 
                    setGeneralError(''); 
                }

                if (response.data.errors) {
                    alert(response.data.errors);
                }
                if (response.data.success) {
                    setShowModal(true);
                    setErrors({}); 
                    setGeneralError(''); 
                } else {
                    setErrors(response.data.errors || {});
                    setGeneralError(''); 
                }
            })
            .catch(error => {
                console.error('Error processing payout:', error);
                const errorResponse = error.response?.data;
                if (errorResponse && errorResponse.errors) {
                    setErrors(errorResponse.errors);
                    setGeneralError(''); 
                } else {
                    setGeneralError('An error occurred. Please try again.');
                    setErrors({}); 
                }
            });
        } else {
            setErrors({
                address: ['The address field is required.'],
                amount: ['The amount field is required.'],
            });
            setGeneralError(''); 
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setAddress('');
        setAmount('');
        setTotalAfterFee('0.00');
    };

    return (
        <div>
           <div dir={t('direction')} style={{ display: "flex", justifyContent: "center" }}>
  <div className="withdrawal-container">
    <div className="header">
      <h2>{t('payout_details')}</h2>
      <button className="back-button" onClick={() => window.history.back()}>
        {t('back')}
        <svg style={{transform: t('rotate')}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right">
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <polyline points="12 5 19 12 12 19"></polyline>
        </svg>
      </button>
    </div>

    {generalError && (
      <div className="error-message" style={{ marginBottom: '20px' }}>
        <p>{generalError}</p>
      </div>
    )}

    <form id="withdrawal-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label dir={t('direction')} htmlFor="withdraw-address">{t('payout_address')}</label>
        <input
          type="text"
          id="withdraw-address"
          className="input-field"
          placeholder={t('enter_payout_address')}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
        {errors.address && (
          <div className="error-message">
            {errors.address.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}
      </div>
      <div className="form-group">
        <label dir={t('direction')} htmlFor="network-name">{t('network')}</label>
        <input
          type="text"
          id="network-name"
          className="input-field"
          value={t('bnb_network')}
          readOnly
        />
      </div>
      <div className="form-group">
        <label dir={t('direction')} htmlFor="withdraw-amount">{t('payout_amount')} ( USDT )</label>
        <input
          type="number"
          id="withdraw-amount"
          className="input-field"
          placeholder={t('enter_amount')}
          value={amount}
          onChange={handleAmountChange}
          required
        />
        <small dir={t('direction')} id="available-balance">{t('available_balance')}: {balance}</small>
        {errors.amount && (
          <div className="error-message">
            {errors.amount.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}
      </div>
      <div  className="total-amount">
        {t('total_after_fee')} (0.3 USDT): <span id="total-after-fee">${totalAfterFee}</span>
      </div>
      <button type="submit" className="withdraw-button">{t('Confirm')}</button>
    </form>

    {showModal && (
      <div id="success-modal" className="modal" style={{ display: 'flex' }}>
        <div className="modal-content">
          <span className="checkmark">&#10004;</span>
          <p>{t('withdrawal_successful')}</p>
          <button id="close-modal-button" className="close-modal-button" onClick={handleCloseModal}>{t('done')}</button>
        </div>
      </div>
    )}
  </div>
</div>

        </div>
    );
}

export default WithdrawalForm;
