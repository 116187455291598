import React, { useState, useEffect } from 'react';
import './SignIn.css';
import axios from 'axios'; 
import { Link, useNavigate } from 'react-router-dom'; 
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useTranslation } from 'react-i18next';
const SignIn = () => {
    const { t } = useTranslation('sign'); // تحديد Namespace
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate(); 

    const MAX_ATTEMPTS = 10; // الحد الأقصى للمحاولات
    const LOCKOUT_KEY = 'lockout'; // مفتاح الحالة المحظورة في localStorage
    const LOCKOUT_TIME_SHORT = 60 * 1000; // 1 دقيقة
    const LOCKOUT_TIME_LONG = 10 * 60 * 1000; // 10 دقائق

    useEffect(() => {
        // تحقق مما إذا كان المستخدم مسجل الدخول بالفعل
        const token = localStorage.getItem('token');
        if (token) {
            // إذا كان المستخدم مسجل الدخول، أعد التوجيه إلى الصفحة الحالية
            toast.info('You are already logged in!');
            navigate(-1); // يعيد المستخدم إلى الصفحة السابقة
        }
    }, [navigate]);

    useEffect(() => {
        const attempts = parseInt(localStorage.getItem('attempts')) || 0;
        const lockoutEnd = parseInt(localStorage.getItem('lockoutEnd')) || 0;
        const currentTime = Date.now();

        if (currentTime < lockoutEnd) {
            setError('You are currently locked out due to too many failed login attempts. Please try again later.');
        } else {
            localStorage.removeItem('lockoutEnd'); // إزالة القفل إذا لم يكن مفعلًا
        }
    }, []);

    const validateForm = async (event) => {
        event.preventDefault();
        setEmailError('');
        setPasswordError('');
        setError('');
    
        let valid = true;
    
        if (!email) {
            setEmailError('Email is required.');
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Please enter a valid email address.');
            valid = false;
        }
    
        if (!password) {
            setPasswordError('Password is required.');
            valid = false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters.');
            valid = false;
        }
    
        // تحقق من حالة القفل
        const attempts = parseInt(localStorage.getItem('attempts')) || 0;
        const lockoutEnd = parseInt(localStorage.getItem('lockoutEnd')) || 0;
        const currentTime = Date.now();
    
        if (currentTime < lockoutEnd) {
            setError('You are currently locked out due to too many failed login attempts. Please try again later.');
            toast.error('You are locked out. Please wait and try again.');
            return;
        }
    
        if (valid) {
            try {
                const response = await axios.post('http://sanaddigital.com/public/api/login', {
                    email: email,
                    password: password,
                });
    
                toast.success('Login successful! Redirecting to dashboard...');
                localStorage.setItem('email', email);
                localStorage.setItem('token', response.data.token);
    
                // إعادة تعيين المحاولات بعد تسجيل الدخول الناجح
                localStorage.removeItem('attempts');
                localStorage.removeItem('lockoutEnd');
    
                navigate('/Profile');
            } catch (error) {
                const newAttempts = attempts + 1; // زيادة عدد المحاولات عند الخطأ
                localStorage.setItem('attempts', newAttempts);
    
                if (newAttempts >= MAX_ATTEMPTS) {
                    localStorage.setItem('lockoutEnd', currentTime + LOCKOUT_TIME_LONG); // قفل لمدة 10 دقائق
                    setError('You have been locked out due to too many failed login attempts.');
                    toast.error('You are locked out for 10 minutes due to too many failed attempts.');
                } else if (newAttempts >= 5) {
                    localStorage.setItem('lockoutEnd', currentTime + LOCKOUT_TIME_SHORT); // قفل لمدة دقيقة
                    setError('Too many failed attempts. You are locked out for 1 minute.');
                    toast.error('Too many failed attempts. You are locked out for 1 minute.');
                } else {
                    setError('email or password is incorrect');
                    toast.error('email or password is incorrect');
                }
            }
        }
    };

    return (
        <div className='body'>
            <div className="containerSi">
                <div className="image-section">
                    {/* Image will be here */}
                </div>
                <div dir={t('direction')} className="form-section">
            <h2>{t("sign_in")}</h2>
            <form  id="sign-in-form" onSubmit={validateForm}>
                <div dir={t('direction')} className="input-group">
                    <label  htmlFor="email">{t("email_label")}</label>
                    <input
                        dir='ltr'
                        type="email"
                        id="email"
                        placeholder={t("email_placeholder")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && <div className="error">{emailError}</div>}
                </div>
                <div dir={t('direction')} className="input-group">
                    <label htmlFor="password">{t("password_label")}</label>
                    <input
                    dir='ltr'
                        type="password"
                        id="password"
                        placeholder={t("password_placeholder")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordError && <div className="error">{passwordError}</div>}
                </div>
                <div className="options">
                    <label>
                        <input style={{ width: "max-content" }} type="checkbox" /> {t("remember_me")}
                    </label>
                </div>
                <div className="buttons">
                    <button type="submit" className="sign-in">
                        {t("sign_in")}
                    </button>
                    <Link to="/SignUp">
                        <button type="button" className="sign-up">
                            {t("sign_up")}
                        </button>
                    </Link>
                </div>
            </form>
            {error && <div className="error">{error}</div>}
        </div>
            </div>
            <ToastContainer /> {/* عرض التوستات */}
        </div>
    );
};

export default SignIn;
