import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css'; // تأكد من أن لديك ملف CSS

function Dashboard() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من إذا كان أدمن

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/SignInSanadDashboard');
  };

  if (!isAdmin) {
    return null; // منع عرض الداشبورد إذا لم يكن أدمن
  }

  return (
    <div style={{marginTop: '-65px'}} className="dashboard">
      <nav className="navbar2">
        <div className="logo225">SANAD DIGITAL</div>
        <div className="nav-center">Welcome Sanad</div>
        <button className="logout-btn2" onClick={handleLogout}>Logout</button>
      </nav>

      <div className="grid-container">
        <Link to='/SanadMembers' style={{textDecoration: "none"}} className="grid-item members">
          <i className="fas fa-users icon"></i>
          <h3>Sanad Members</h3>
        </Link>
        <Link to='/SanadStatistics' style={{textDecoration: "none"}} className="grid-item team">
          <i className="fas fa-chart-bar icon"></i>
          <h3>Sanad Statistics</h3>
        </Link>
        <Link to='/SanadWallet' style={{textDecoration: "none"}} className="grid-item wallet">
          <i className="fas fa-wallet icon"></i>
          <h3>Sanad Wallet</h3>
        </Link>
        <Link to='/EditProfile' style={{textDecoration: "none"}} className="grid-item orders">
          <i className="fas fa-hourglass-half icon"></i>
          <h3>Edit Profile</h3>
        </Link>
      </div>
    </div>
  );
}

export default Dashboard;
