import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditProfile.css';
import { useNavigate, NavLink } from 'react-router-dom';
const EditProfile = () => {
  const [newPassword, setNewPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [id_person, setIdPerson] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من إذا كان أدمن
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
  }, [navigate]);
  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'http://sanaddigital.com/public/api/editProfileDashboard',
        {
          password: newPassword,
          email: newEmail,
          id_person: id_person,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

   

      // تحقق من نجاح الطلب باستخدام الرسالة
      if (response.data.message === 'Data Updated successfully') {
        setSuccess('Profile updated successfully!');
        setError(null);
        
        // تفريغ الحقول
        setNewPassword('');
        setNewEmail('');
        setIdPerson('');
      } else {
        setError('Failed to update profile.');
        setSuccess(null);
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Error updating profile. Please try again later.');
      setSuccess(null);
    }
  };

  const goBack = () => {
    window.history.back();
  };
  if (!isAdmin) {
    return null; // منع عرض الداشبورد إذا لم يكن أدمن
  }
  return (
    <div style={{marginTop: '-65px'}} className="edit-profile">
      <div style={{ zIndex: '10', marginBottom: '10px', color: 'black' }} className="back-arrow" onClick={goBack}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <h2 className="edit-profile-title">Edit Profile</h2>
      <form className="edit-profile-form">
      <div className="form-group">
          <label className="form-label">New Email:</label>
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Enter new email"
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            className="form-input"
          />
        </div>
        
        <div className="form-group">
          <label className="form-label">New Code:</label>
          <input
            type="text"
            value={id_person}
            onChange={(e) => setIdPerson(e.target.value)}
            placeholder="Enter new code"
            className="form-input"
          />
        </div>
        <button type="button" onClick={handleSave} className="form-button">
          Save Changes
        </button>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message" style={{ color: 'green' }}>{success}</div>}
      </form>
    </div>
  );
};

export default EditProfile;
