import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './UserTransactions.css';
import { useNavigate, NavLink } from 'react-router-dom';
const UserTransactions = () => {
  const { id } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من إذا كان أدمن
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`http://sanaddigital.com/public/api/userTransactions/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { Payments, Payouts } = response.data;

        const allTransactions = [
          ...Payments.map(payment => ({
            ...payment,
            type: 'Payment',
            date: payment.created_at,
            time: payment.updated_at,
          })),
          ...Payouts.map(payout => ({
            ...payout,
            type: 'Payout',
            date: payout.created_at,
            time: payout.updated_at,
          })),
        ];

        setTransactions(allTransactions);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to fetch transactions');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  const goBack = () => {
    window.history.back(); // للرجوع إلى الصفحة السابقة
  };

  return (
    <div style={{marginTop: '-65px'}} className="user-transactions-container">
       <div style={{ zIndex: "10", marginBottom: '10px', color: 'black' }} className="back-arrow" onClick={goBack}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <h2>User Transactions</h2>
      <div className="transactions-list">
        {transactions.length === 0 ? (
          <div>No Transactions Found</div>
        ) : (
          transactions.map((transaction) => (
            <div key={transaction.id} className={`transaction-card ${transaction.status.toLowerCase()}`}>
              <div className="transaction-info">
                <div className="transaction-type">{transaction.type}</div>
                <div className="transaction-amount">Amount: {transaction.amount}</div>
                <div className="transaction-date">Date: {transaction.date}</div>
                <div className="transaction-time">Time: {transaction.time}</div>
                Status: <div
                  className={`transaction-status ${
                    transaction.status.toLowerCase() === 'paid'
                      ? 'status-paid'
                      : transaction.status.toLowerCase() === 'failed' ||
                        transaction.status.toLowerCase() === 'cancel'
                      ? 'status-failed'
                      : 'status-check'
                  }`}
                >
                {transaction.status}
                </div>
                <div className="transaction-wallet">Wallet: {transaction.address}</div>
               
              </div>
              <a style={{textDecoration: 'none', color: 'white'}} href={transaction.url} target="_blank" rel="noopener noreferrer" className="view-details-btn">View Details</a>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserTransactions;
