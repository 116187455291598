import React from "react";
import i18next from "i18next";

const NumberTranslator = ({ number }) => {
  // تحديد لغة التنسيق بناءً على اللغة الحالية
  const currentLanguage = i18next.language || "en";

  // إذا كانت القيمة ليست رقمًا، عرضها كما هي
  if (isNaN(number)) {
    return <span>{number}</span>;
  }

  // استخراج عدد الأرقام العشرية الموجودة في الرقم
  const decimalPart = number.toString().split(".")[1];
  const fractionDigits = decimalPart ? decimalPart.length : 0;

  // تنسيق الرقم مع الحفاظ على الأصفار بعد العلامة العشرية
  const formattedNumber = new Intl.NumberFormat(currentLanguage === "ar" ? "ar-EG" : "en-US", {
    useGrouping: false, // تعطيل الفاصلات (Grouping)
    minimumFractionDigits: fractionDigits, // عرض الأرقام العشرية الفعلية
    maximumFractionDigits: 20, // عرض حتى 20 رقمًا عشريًا كحد أقصى
  }).format(number);

  return <span>{formattedNumber}</span>;
};

export default NumberTranslator;
