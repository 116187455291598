import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // لتحميل الترجمات من ملفات JSON
  .use(LanguageDetector) // لاكتشاف لغة المستخدم تلقائيًا
  .use(initReactI18next) // ربط i18n بـ React
  .init({
    lng: localStorage.getItem('language') || 'en', // استرجاع اللغة المخزنة أو تعيين اللغة الافتراضية (الإنجليزية)
    supportedLngs: ['en', 'ar'], // اللغات المدعومة
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // مسار ملفات الترجمة
    },
    detection: {
      order: ['htmlTag', 'cookie', 'localStorage', 'navigator'], // ترتيب اكتشاف اللغة
      caches: ['cookie', 'localStorage'], // تخزين اللغة المكتشفة
    },
    react: {
      useSuspense: true, // لتحميل الترجمات بشكل ديناميكي
    },
  });

export default i18n;
