import React from 'react'
import { useState, useEffect } from "react";
import "./BackToTop.css"
function BackToTop() {

    const [backToTopButton, setBackToTopButton] = useState(false);


    useEffect(() => {
      window.addEventListener("scroll", () =>{
        if(window.scrollY > 800) {
          setBackToTopButton(true)
         } else {
          setBackToTopButton(false)
        }
    })
    }, [])

    const scrollUp = () => {

        window.scrollTo({
        top: 0,
        behavior: "smooth"
        })
        
        }

  return (
    <div>
    {backToTopButton && (
<a style={{textDecoration: "none"}} className='back-to-top' onClick={scrollUp}>
<i className="fa fa-angle-up"></i>
</a>
    )}

    </div>
  )
}

export default BackToTop