import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./userTeamMembers.css";
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
function UserTeamMembers() {
  const { id } = useParams();


  const [usersData, setUsersData] = useState([]); // لتخزين أسماء المستخدمين
  const [teamMembersData, setTeamMembersData] = useState({}); // لتخزين بيانات المستويات
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من إذا كان أدمن
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
  }, [navigate]);
  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch(`http://sanaddigital.com/public/api/userTeamMembers/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        // إذا لم يكن هناك استجابة صحيحة، أعد القيمة null بدلاً من الخطأ
        return { usersData: [], teamMembersData: {} }; // اجعلها فارغة لتجنب الخطأ
      }
      return response.json();
    })
    .then(data => {
     
      setUsersData(data.usersData); // تخزين بيانات المستخدمين
      setTeamMembersData(data.teamMembersData); // تخزين بيانات المستويات
      setLoading(false);
    })
    .catch(error => {
      setError(error);
      setLoading(false);
    });
  }, [id]);

  if (loading) return <p>Loading...</p>;

  return (
    <div style={{marginTop: '-65px'}}>
      <div className='Main2'>
        <div className="container2">
          <div className="header2">
            <button className="back-button" onClick={() => window.history.back()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                className="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
              Back
            </button>
            <h1 style={{ textAlign: "center", color: "#106C65" }}>Team Members</h1>
          </div>
          <div dir='ltr' className="members-list">
          <div style={{marginRight: '0px'}} className='LOWLEV'>
        <p style={{ paddingLeft: '13px', marginBottom: '3px' }}>1</p>
        <p style={{ paddingLeft: '14px', marginBottom: '3px' }}>2</p>
        <p style={{ paddingLeft: '14px', marginBottom: '3px' }}>3</p>
        <p style={{ paddingLeft: '14px', marginBottom: '3px' }}>4</p>
        <p style={{ paddingLeft: '14px', marginBottom: '3px', paddingRight: "12px" }}>5</p>
      </div>
            {usersData.length === 0 ? ( // تحقق مما إذا كانت القائمة فارغة
              <p>No team members found.</p> // رسالة عند عدم وجود مستخدمين
            ) : (
              usersData.map((user) => (
                <div className="member-item2" key={user.id}>
                  <p style={{ marginBottom: "0px" }} className="member-name">{user.name}</p>
                  <div className="member-status" style={{ alignItems: "flex-end" }}>
                    {/* الدوائر الخاصة بالمستويات */}
                    {Array.from({ length: 5 }, (_, index) => {
                      const levelNumber = index + 1; // للحصول على الأرقام من 1 إلى 5
                      const isActive = teamMembersData[`level${levelNumber}`] && teamMembersData[`level${levelNumber}`].some(level => level.user_id === user.id); // تحقق مما إذا كان المستخدم في هذا المستوى
                      return (
                        <span key={index} className={`dot ${isActive ? 'active2' : 'deleted'}`}></span>
                      );
                    })}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>  
      </div>
    </div>
  );
}

export default UserTeamMembers;
