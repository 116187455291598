import React, { useRef, useEffect, useState } from 'react';
import './Nav.css';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../../Translation/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Nav = () => {
  const { t, i18n } = useTranslation('home'); // تحديد Namespace
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // حالة القائمة الجانبية
  const navigate = useNavigate();
  const isRTL = i18n.language === 'ar'; // تحديد إذا كانت اللغة RTL (عربية)
  const sidebarRef = useRef(null); // مرجع للقائمة الجانبية


  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false); // إغلاق القائمة
    }
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // إضافة حدث لإغلاق القائمة عند النقر خارجها
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // فحص حالة التوكن كل ثانية
  useEffect(() => {
    const intervalId = setInterval(() => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    }, 1000); // فحص كل ثانية (1000 مللي ثانية)

    // تنظيف الـ interval عند إزالة الـ component
    return () => clearInterval(intervalId);
  }, []);

 
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1188) {
        setIsSidebarOpen(false); // أغلق القائمة تلقائياً
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavLinkClick = () => {
    setIsSidebarOpen(false); // إغلاق القائمة الجانبية عند النقر على رابط
  };

  const toggleSidebar = (event) => {
    event.stopPropagation(); // منع تفعيل حدث النقر خارج القائمة
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/SignIn');
  };

  return (
    <div>
      <nav  className='navbar navbar-expand-xl header' id='naav'>
        <div style={{ maxWidth: "1400px", padding: '0px' }} className="container">
          <NavLink to="/">
            <img  src="./images/SanadNewLogo.png" alt="logo" />
          </NavLink>
          <div style={{marginRight: '-10px'}}  className="navbar-toggler">
             <LanguageSwitcher />
          <button 
            className="navbar-toggler" 
            type="button" 
            onClick={toggleSidebar} 
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul dir={t('direction')} style={{ margin: '0px', padding: '0px' }} className="navbar-nav switcher ml-auto">
              <li className="nav-item p-2">
                <NavLink className="nav-link" exact to="/" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_home')} <span className="sr-only">(current)</span></NavLink>
              </li>
              <li className="nav-item p-2">
                <NavLink className="nav-link" to="/Subscriptions" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_subscriptions')}</NavLink>
              </li>
              <li className="nav-item p-2">
                <NavLink className="nav-link" to="/Wallet" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_wallet')}</NavLink>
              </li>
              <li className="nav-item p-2">
                <NavLink className="nav-link" to="/Profile" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_profile')}</NavLink>
              </li>
              <li className="nav-item p-2">
                <NavLink className="nav-link" to="/ContactUS" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_contact_us')}</NavLink>
              </li>
            </ul>

            <div style={{ display: "flex" }} className="btn-wrapper">
              {isLoggedIn ? (
                <button className="login-btn advantage style-012" onClick={handleLogout}>{t('footer.logout')}</button>
              ) : (
                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                  <NavLink style={{ backgroundColor: '#32A8A2' }} to="/SignIn" className="login-btn advantage style-012" onClick={handleNavLinkClick}>{t('footer.signin')}</NavLink>
                  <NavLink to="SignUp" className="login-btn advantage style-012" onClick={handleNavLinkClick}>{t('footer.freemember')}</NavLink>
                </div>
              )}
            </div>
            <LanguageSwitcher />
          </div>
        </div>
      </nav>

      {/* القائمة الجانبية */}
      <div  className={`sidebar335 ${isSidebarOpen ? 'open' : ''} ${isRTL ? 'ltr' : 'ltr'}`}>
      <div style={{color: 'white', textAlign: 'center', marginTop: '-25px', marginBottom: '33px'}}>
        <h3>{t('welcome2')}</h3>
        <p>omar</p>
      </div>
  <ul style={{ margin: '0px', padding: '0px' }} className="navbar-nav switcher ml-auto">
    <li  className="nav-item p-2">
      <NavLink style={{borderTop: '1px solid #444'}} className="nav-link" exact to="/" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_home')} <span className="sr-only">(current)</span></NavLink>
    </li>
    <li className="nav-item p-2">
      <NavLink className="nav-link" to="/Subscriptions" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_subscriptions')}</NavLink>
    </li>
    <li className="nav-item p-2">
      <NavLink className="nav-link" to="/Wallet" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_wallet')}</NavLink>
    </li>
    <li className="nav-item p-2">
      <NavLink className="nav-link" to="/Profile" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_profile')}</NavLink>
    </li>
    <li className="nav-item p-2">
      <NavLink className="nav-link" to="/ContactUS" activeClassName="active" onClick={handleNavLinkClick}>{t('footer.page_contact_us')}</NavLink>
    </li>
  </ul>
  <div className="btn-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
    {isLoggedIn ? (
           <button style={{width: '100%', marginTop: '10px'}} className="login-btn advantage style-012" onClick={handleLogout}>{t('footer.logout')}</button>
    ) : (
      <div style={{ width: '100%',  display: 'flex', justifyContent: 'center', flexWrap: "wrap" }}>
        <NavLink style={{width: '100%', fontSize: '12px', textAlign: 'center', marginTop: '10px'}} to="SignUp" className="login-btn advantage style-012" onClick={handleNavLinkClick}><span style={{fontSize: '14px', fontWeight: 'bold'}}>{t('footer.freemember2')}</span>{t('footer.freemember')}</NavLink>
        <NavLink style={{ backgroundColor: '#32A8A2', textAlign: "center",  }} to="/SignIn" className="login-btn advantage style-012" onClick={handleNavLinkClick}>{t('footer.signin')}</NavLink>
      </div>
    )}
  </div>
  
</div>
{isSidebarOpen && (
        <div className="backdrop" onClick={closeSidebar}></div>
      )}
    </div>
  );
}

export default Nav;
