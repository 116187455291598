import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UnderreviewMembers.css';
import { Link, useNavigate } from 'react-router-dom';

const UnderreviewMembers = () => {
  const [underReviewMembers, setUnderReviewMembers] = useState([]);
  const [loading, setLoading] = useState(true); // حالة التحميل
  const navigate = useNavigate(); // تعريف navigate

  useEffect(() => {
    const token = localStorage.getItem('token'); // استرجاع توكن المصادقة من localStorage
    if (!token) {
      console.error('No authentication token found');
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
      return;
    }

    const checkAdmin = async () => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/checkAdmin', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.data.is_admin) {
          navigate('/404'); // توجيه المستخدم غير الأدمين إلى صفحة 404
        } else {
          // إذا كان أدمين، استرجع الأعضاء تحت المراجعة
          await fetchUnderReviewMembers(token);
        }
      } catch (error) {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه إلى صفحة 404 في حال حدوث خطأ
      }
    };

    const fetchUnderReviewMembers = async (token) => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/reviewMembers', {
          headers: {
            'Authorization': `Bearer ${token}`, // إضافة التوكن إلى رأس الطلب
          },
        });
       
        setUnderReviewMembers(response.data.reviewMembers); // تعيين الأعضاء الذين هم تحت المراجعة
        setLoading(false); // تحديث حالة التحميل بعد استرجاع البيانات
      } catch (error) {
        console.error('Error fetching under review members:', error.response ? error.response.data : error.message);
      }
    };

    checkAdmin(); // التحقق من حالة الأدمين
  }, [navigate]);

  const goBack = () => {
    window.history.back(); // للرجوع إلى الصفحة السابقة
  };

  if (loading) {
    return null; // لا تظهر أي شيء أثناء التحميل
  }

  return (
    <div style={{marginTop: '-65px'}} className="under-review-members-page">
      <div className="back-arrow" onClick={goBack}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <h2>Under Review Members</h2>
      <div className="members-list">
        {underReviewMembers.map((member) => (
          <div key={member.id} className="member-card3">
            <div className="member-info268">
              <span className="info-label3">Name:</span> {member.name}
            </div>
            <div className="member-info268">
              <span className="info-label3">ID:</span> {member.id}
            </div>
            <div className="member-info268">
              <span className="info-label3">Registration Date:</span> {new Date(member.created_at).toLocaleDateString()}
            </div>
            <div className="member-actions">
              <Link to={`/UserProfile/${member.id}`} style={{ textDecoration: "none", color: "white" }} className="profile-button">View Profile</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnderreviewMembers;
