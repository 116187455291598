import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
import './SanadWallet.css';

// Modal Component
function Modal({ isOpen, onClose, onConfirm }) {
  const [amount, setAmount] = useState('');

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(amount);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Top Up</h2>
        <input
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button onClick={handleConfirm}>Confirm</button>
      </div>
    </div>
  );
}

// Wallet Component
function SanadWallet() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [paymentTransactions, setPaymentTransactions] = useState([]);
  const [payoutTransactions, setPayoutTransactions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من إذا كان أدمن
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
    // Fetch current balance from the API
    axios.get('http://sanaddigital.com/public/api/user/current-balance', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        setBalance(response.data.data || 0); // Set the balance, ensure it's a number
      })
      .catch(error => {
        console.error('Error fetching balance:', error);
      });

    // Fetch payment transactions
    axios.get('http://sanaddigital.com/public/api/user/current-balance-payment', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        setPaymentTransactions(response.data.data || []); // Ensure it's an array
        
      })
      .catch(error => {
        console.error('Error fetching payment transactions:', error);
      });

    // Fetch payout transactions
    axios.get('http://sanaddigital.com/public/api/user/current-balance-payout', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
       

        setPayoutTransactions(response.data || []); // Ensure it's an array
      })
      .catch(error => {
        console.error('Error fetching payout transactions:', error);
      });

  }, [navigate]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const confirmTopUp = (amount) => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      navigate('/404');
      return;
    }
    

    axios.post('http://sanaddigital.com/public/api/user/payment', 
      { amount: parseFloat(amount) }, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then(response => {
       if (response.data.url) {
           
            window.location.href = response.data.url;
       }
    })
    .catch(error => {
      console.error('Error processing top-up:', error);
    });
  };
  const goBack = () => {
    window.history.back(); // للرجوع إلى الصفحة السابقة
  };
  if (!isAdmin) {
    return null; // منع عرض الداشبورد إذا لم يكن أدمن
  }
  return (
    <div style={{marginTop: '-65px'}}>
        <h1 style={{textAlign: 'center', marginTop: '50px' }}>Sanad Wallet</h1>
        <div className="back-arrow" onClick={goBack}>
        <i style={{color: 'black'}} className="fas fa-arrow-left"></i>
        </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>  
        <div className="wallet-container">
          <div className="balance-section">
            <h2>Current balance</h2>
            <h1>${balance}</h1> {/* Display the balance */}
          </div>
          <div className="buttons2">
            <NavLink to="/Payout" style={{ textDecoration: 'none' }}>
              <button style={{width: '300px'}} className="payout">Payout</button>
            </NavLink>
          </div>
          

          
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmTopUp}
      />
    </div>
  );
}

export default SanadWallet;
