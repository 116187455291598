import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
import './wallet.css';
import { useTranslation } from 'react-i18next';
import NumberTranslator from '../../../Translation/NumberTranslator';
function Modal({ isOpen, onClose, onConfirm, isLoggedIn }) {
  const { t } = useTranslation('wallet'); // تحديد Namespace
  const [amount, setAmount] = useState('');
  if (!isOpen) return null;

  const handleConfirm = () => {
    if (isLoggedIn) {
      onConfirm(amount);
    }
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" style={{color: 'black'}} onClick={onClose}>X</button>
        <h2>{t('top_up')}</h2>
        <input
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button onClick={handleConfirm}>{t('Confirm')}</button>
      </div>
    </div>
  );
}

function Wallet() {
  const { t } = useTranslation('wallet'); // تحديد Namespace
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [totals, setTotals] = useState({
    totalPayments: 0,
    totalPayouts: 0,
    totalTeamRewards: 0,
    totalSharingRewards: 0,
  });
  const [statusAccept, setStatusAccept] = useState(0);
  const navigate = useNavigate();
  const isLoggedIn = Boolean(localStorage.getItem('token'));

  useEffect(() => {
    const fetchStatus = async () => {
      if (isLoggedIn) {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get('http://sanaddigital.com/public/api/teamStats', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setStatusAccept(response.data.user.status_accept);
        } catch (error) {
          console.error('Error fetching status_accept:', error);
        }
      }
    };

    fetchStatus();
  }, [isLoggedIn]);

  const fetchDailyTotals = async () => {
    if (isLoggedIn && statusAccept === 1) {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/payments', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTotals(response.data);
       
      } catch (error) {
        console.error('Error fetching transaction totals:', error);
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn && statusAccept === 1) {
      const token = localStorage.getItem('token');
      
      axios
        .get('http://sanaddigital.com/public/api/user/current-balance', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setBalance(response.data.data || 0);
        })
        .catch((error) => {
          console.error('Error fetching balance:', error);
        });

      fetchDailyTotals();

 
    }
  }, [isLoggedIn, statusAccept]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const confirmTopUp = (amount) => {
    if (isLoggedIn && statusAccept === 1) {
      const token = localStorage.getItem('token');

      axios
        .post(
          'http://sanaddigital.com/public/api/user/payment',
          { amount: parseFloat(amount) },
          { headers: { Authorization: `Bearer ${token}` }}
        )
        .then((response) => {
          if (response.data.url) {
            window.location.href = response.data.url;
          }
        })
        .catch((error) => {
          console.error('Error processing top-up:', error);
        });
    } 
  };

  return (
    <div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div className="wallet-container">
      <div className="balance-section">
        <h2>{t('current_balance')}</h2>
        
        <h1>$ <NumberTranslator  number={isLoggedIn && statusAccept === 1 ? balance : 0} /></h1>
      </div>
      {isLoggedIn && statusAccept === 1 ? 
        <div dir={t('direction')} className="buttons2">
          <button className="topup" onClick={openModal}>{t('top_up')}</button>
          <NavLink to="/Payout" style={{ textDecoration: 'none' }}>
            <button className="payout">{t('payout')}</button>
          </NavLink>
        </div> : 
        <div dir={t('direction')} className="buttons2">
          <button className="topup" disabled={true}>{t('top_up')}</button>
          <NavLink style={{ textDecoration: 'none' }}>
            <button disabled={true} className="payout">{t('payout')}</button>
          </NavLink>
        </div>
      }

      <div dir={t('direction')} className="transactions">
        <h3 dir={t('direction')}>{t('recent_transactions')}</h3>
        
        <ul>
         <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'left',  padding: "0px 10px" }} className='totaalDiv'>
          <h6></h6>
          <h4>{t('total')}</h4>
        </div> 
  <li>
    <span className="transaction-details">
      <span className="transaction-name">{t('top_up')}</span>
      <span className="transaction-amount2">
        <NumberTranslator number={isLoggedIn && statusAccept === 1 ? totals.totalPayments : 0} /> $
      </span>
    </span>
  </li>
  <li>
    <span className="transaction-details">
      <span className="transaction-name">{t('payout')}</span>
      <span className="transaction-amount2 negative">
        -<NumberTranslator number={isLoggedIn && statusAccept === 1 ? totals.totalPayouts : 0} /> $
      </span>
    </span>
  </li>
  <li>
    <span className="transaction-details">
      <span className="transaction-name">{t('team_rewards')}</span>
      <span className="transaction-amount2">
        <NumberTranslator number={isLoggedIn && statusAccept === 1 ? totals.totalTeamRewards : 0} /> $
      </span>
    </span>
  </li>
  <li>
    <span className="transaction-details">
      <span className="transaction-name">{t('sharing_rewards')}</span>
      <span className="transaction-amount2">
        <NumberTranslator number={isLoggedIn && statusAccept === 1 ? totals.totalSharingRewards : 0} /> $
      </span>
    </span>
  </li>
</ul>

        {isLoggedIn && statusAccept === 1 ? 
          <NavLink to="/AllTransactions" style={{ textDecoration: 'none' }}>
            <button className="view-all">{t('show_all_transactions')}</button>
          </NavLink> :
          <NavLink style={{ textDecoration: 'none' }}>
            <button disabled={true} className="view-all">{t('show_all_transactions')}</button>
          </NavLink>
        }
      </div>
    </div>
  </div>

  <Modal 
    isOpen={isModalOpen} 
    onClose={closeModal} 
    onConfirm={confirmTopUp} 
    isLoggedIn={isLoggedIn} 
  />
</div>

  );
}

export default Wallet;
