import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './VerifiedUserProfile.css';
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
const VerifiedUserProfile = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const token = localStorage.getItem('token');
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من إذا كان أدمن
  const basePath = 'http://sanaddigital.com/storage/app/public/';
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/Dashboard'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
    const fetchProfile = async () => {
      try {
        const response = await fetch(`http://sanaddigital.com/public/api/verifiedUserProfile/${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
       
        setProfile(data.verifiedUserProfile);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, [id, token]);

  const openImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  if (!profile) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{marginTop: '-65px'}} className="user-profile-container">
      <div className="back-arrow" onClick={() => window.history.back()}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <div className="user-profile-card">
        <div className="user-profile-picture">
          <img src={profile.image ? `${basePath}${profile.image}` : 'default_image_path.jpg'} alt="Profile" />
        </div>
        <div className="user-profile-details">
          <p><strong>Name:</strong> <span>{profile.name}</span></p>
          <p><strong>ID:</strong> {profile.id_person}</p>
          <p><strong>Email:</strong> <span>{profile.email}</span></p>
          <p><strong>Phone:</strong> <span>{profile.phone}</span></p>
          <p><strong>National ID:</strong> <span>{profile.identity}</span></p>
        </div>
        <div className="user-id-images">
          <div className="user-id-image">
            <p><strong>Front ID Card:</strong></p>
            <img
              src={`${basePath}${profile.upload_front_id}`}
              alt="Front ID Card"
              onClick={() => openImageModal(`${basePath}${profile.upload_front_id}`)}
            />
          </div>
          <div className="user-id-image">
            <p><strong>Back ID Card:</strong></p>
            <img
              src={`${basePath}${profile.upload_back_id}`}
              alt="Back ID Card"
              onClick={() => openImageModal(`${basePath}${profile.upload_back_id}`)}
            />
          </div>
        </div>
      </div>

      {showImageModal && (
        <div className="custom-modal-overlay" onClick={closeImageModal}>
          <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Enlarged ID" className="custom-modal-image" />
            <button className="custom-modal-close" onClick={closeImageModal}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifiedUserProfile;
