import React from 'react';
import './App.css';
import Wallet from './Componend/Wallet/WalletMain/wallet';
import WithdrawalForm from './Componend/Wallet/Payout/Payout';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Profile from './Componend/Profile/Profile';
import ContactUs from './Componend/ContactUs/ContactUs';
import Subscriptions from './Componend/Subscriptions/SubscriptionsMain/Subscriptions';
import AllMember from './Componend/Subscriptions/ShowAllMember/AllMember';
import AllTransactions from './Componend/Wallet/AllTransactions/AllTransactions';
import Home from './Componend/Home/Home';
import Nav from './Componend/Nav/Nav';
import Footer from './Componend/Footer/Footer';
import SignIn from './Componend/SignIn/SignIn';
import SignUp from './Componend/Signup/Signup';
import Comment from './Componend/comment';
import BackToUp from './Componend/BackToTop';
import BackToTop from './Componend/BackToTop';
import Dashboard from './Componend/Dashboard/Dashboard';
import SanadMembers from './Componend/Dashboard/SanadMembers/SanadMembers';
import SignUpMembers from './Componend/Dashboard/SanadMembers/SignUpMembers/SignUpMembers';
import UnderreviewMembers from './Componend/Dashboard/SanadMembers/UnderreviewMembers/UnderreviewMembers';
import UserProfile from './Componend/Dashboard/SanadMembers/UnderreviewMembers/UserProfile/UserProfile';
import SanadStatistics from './Componend/Dashboard/SanadStatistics/SanadStatistics';
import SignInSanadDashboard from './Componend/Dashboard/SignInSanadDashboard/SignInSanadDashboard';
import VerifiedMembers from './Componend/Dashboard/SanadMembers/VerifiedMembers/VerifiedMembers';
import UserTeamMembers from './Componend/Dashboard/SanadMembers/VerifiedMembers/userTeamMembers/userTeamMembers';
import VerifiedUserProfile from './Componend/Dashboard/SanadMembers/VerifiedMembers/VerifiedUserProfile/VerifiedUserProfile';
import UserTransactions from './Componend/Dashboard/SanadMembers/VerifiedMembers/UserTransactions/UserTransactions';
import SanadWallet from './Componend/Dashboard/SanadWallet/SanadWallet';
import EditProfile from './Componend/Dashboard/EditProfile/EditProfile';
import { Suspense } from 'react';
import SyncLoader from "react-spinners/SyncLoader"; // استيراد الـ Spinner
function App() {
  document.addEventListener(
    'touchstart',
    function (event) {
      if (event.touches.length > 1) {
        // منع التكبير فقط عند اللمس المتعدد
        event.preventDefault();
      }
    },
    { passive: false }
  );

  document.addEventListener('wheel', function(event) {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }, { passive: false });
  
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();

  const noFooterPaths = ['/SignIn', '/SignUp', '/Dashboard', '/SanadMembers', '/SignUpMembers', '/UnderreviewMembers', '/UserProfile', '/SanadStatistics', '/SignInSanadDashboard', '/VerifiedMembers', "/userTeamMembers", '/VerifiedUserProfile', '/UserTransactions','/SanadWallet', '/EditProfile', '/UserProfile/', '/Payout'];

 const noNav = ['/Dashboard', '/SanadMembers', '/SignUpMembers', '/UnderreviewMembers', '/UserProfile', '/SanadStatistics', '/SignInSanadDashboard', '/VerifiedMembers', '/userTeamMembers', '/VerifiedUserProfile', '/UserTransactions','/SanadWallet', '/EditProfile', '/Payout'];

  const showFooter = !noFooterPaths.some(path => 
    new RegExp(`^${path}(/\\d+)?$`).test(location.pathname)
  );
  
  const showNav = !noNav.some(path => 
    new RegExp(`^${path}(/\\d+)?$`).test(location.pathname)
  );


  
  return (
    <div className='App' style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
 <Suspense fallback={<div className="loader-container">
                    <SyncLoader color="#36d7b7" size={30} />
                </div>}>
          {showNav && <Nav />}      
    <div style={{ flex: 1, paddingTop: '65px' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Wallet" element={<Wallet />} />
        <Route path="/Payout" element={<WithdrawalForm />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Subscriptions" element={<Subscriptions />} />
        <Route path="/AllMember" element={<AllMember />} />
        <Route path="/AllTransactions" element={<AllTransactions />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/SanadMembers" element={<SanadMembers />} />
        <Route path="/SignUpMembers" element={<SignUpMembers />} />
        <Route path="/UnderreviewMembers" element={<UnderreviewMembers />} />
        <Route path="/UserProfile/:id" element={<UserProfile />} />
        <Route path="/SanadStatistics" element={<SanadStatistics />} />
        <Route path="/SignInSanadDashboard" element={<SignInSanadDashboard />} />
        <Route path="/VerifiedMembers" element={<VerifiedMembers />} />
        <Route path="/userTeamMembers/:id" element={< UserTeamMembers />} />
        <Route path="/VerifiedUserProfile/:id" element={< VerifiedUserProfile />} />
        <Route path="/UserTransactions/:id" element={< UserTransactions />} />
        <Route path="/SanadWallet" element={< SanadWallet />} />
        <Route path="/EditProfile" element={< EditProfile />} />
      </Routes>
    </div>
    {showFooter && <Footer />}
    <BackToTop/>
    </Suspense>
  </div>
  );
}

export default App;