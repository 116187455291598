import React, { useState, useEffect } from 'react';
import "./AllMember.css";

function AllMember() {
  const [teamStats, setTeamStats] = useState(null);  // لتخزين البيانات
  const [loading, setLoading] = useState(true);       // لتتبع حالة التحميل
  const [error, setError] = useState(null);

  const fetchData = () => {
    const token = localStorage.getItem('token'); // احصل على التوكين من localStorage

    fetch('http://sanaddigital.com/public/api/teamStats', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // إرسال التوكين في رأس الطلب
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
     setTeamStats(data);
      setLoading(false);
    })
    .catch(error => {
      setError(error);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData(); // جلب البيانات عند تحميل الصفحة لأول مرة

    // إعداد تحديث لحظي كل 10 ثواني (يمكنك تغيير المدة)
    const intervalId = setInterval(fetchData, 4000);

    // تنظيف التحديث عند إلغاء تحميل المكون
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // دالة للتحقق إذا كان المستخدم مشترك في مستوى معين باستخدام levelsTeam من الـ API
  const isUserSubscribedToLevel = (userId, level) => {
    if (!teamStats || !teamStats.level_Team) return false; // تأكد من وجود البيانات

    const levelUsers = teamStats.level_Team[`level${level}`] || [];
    return levelUsers.some(member => member.user_id === userId);
  };
  return (
    <div>
      <div className='Main2'>
        <div className="container2">
          <div className="header2">
            <button className="back-button" onClick={() => window.history.back()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                  className="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
              Back
            </button>
            <h1 style={{textAlign: "center", color: "#106C65"}}>Team Members</h1>
          </div>
          <div dir='ltr' className="members-list">
            <div className='LOWLEV'>
              {[1, 2, 3, 4, 5].map(level => (
                <p key={level} style={{ fontSize: "17px", paddingLeft: '15px', marginBottom: '3px' }}>{level}</p>
              ))}
            </div>
            {teamStats.users.map((user) => (
              <div className="member-item2" key={user.id}>
                <p style={{marginBottom: "0px"}} className="member-name">{user.name}</p>
                <div className="member-status" style={{ alignItems: "flex-end" }}>
                {[1, 2, 3, 4, 5].map(level => (
                    <span
                      key={level}
                      className={`dot ${isUserSubscribedToLevel(user.id, level) ? 'active2' : 'deleted'}`}
                    ></span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>  
      </div>
    </div>
  );
}

export default AllMember;
