import React from 'react'
import "./ContactUs.css"
import Footer from '../Footer/Footer'
import Nav from '../Nav/Nav'
import { useTranslation } from 'react-i18next';
function ContactUs() {
  const { t } = useTranslation('home'); // تحديد Namespace
  return (
    <div>
 
     <div className='PrImg'>
        <img  src="./images/Contact-Banner.jpg" alt=""/>
  <h1><span>{t('footer.sportteam')} <br/> </span> {t('footer.sportteam2')}</h1>
  </div>
    <div style={{display: "block"}} className="container py-5">
        <div className="row justify-content-center mt-4">
            <div className="card">
                <div className="background">
                </div>
                <div className="logo">
                    <img src="./images/logo.png" className="card-img-top" alt="..."/>
                </div>
                <div className="name">
                    <h3 style={{color: "gold"}}>Manager</h3>
                    <h5 style={{color: "#F5F5F5"}}>Abo Sanad</h5>
                    </div>
                <a href="https://t.me/aboSanad_Esa"><div className="box box1"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M 15 3 C 8.371 3 3 8.371 3 15 C 3 21.629 8.371 27 15 27 C 21.629 27 27 21.629 27 15 C 27 8.371 21.629 3 15 3 z M 19.021484 19.021484 L 15.699219 16.839844 L 14.144531 19.267578 C 14.007781 19.502953 13.784625 19.632812 13.541016 19.632812 C 13.409266 19.632812 13.276016 19.598828 13.158203 19.527344 C 12.828453 19.328109 12.715312 18.889234 12.941406 18.583984 L 14.380859 16.529297 L 10.722656 14.982422 C 10.319906 14.818047 10.271516 14.303047 10.628906 14.075195 L 19.255859 9.03125 C 19.570109 8.8264844 19.983984 9.0622812 19.865234 9.421875 L 17.652344 17.033203 C 17.554594 17.349328 17.242094 17.543859 16.914062 17.498047 C 16.862562 17.490797 16.810922 17.482172 16.759766 17.472656 z"></path>
</svg>
</span></div></a>
              
                <a href="https://wa.me/+0201004208289"><div className="box box2"> <span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.373 3 3 8.373 3 15c0 2.554.78 4.92 2.137 6.888L3 27l5.213-2.113C10.074 26.22 12.453 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3zm4.802 17.77c-.271.75-1.353 1.393-2.107 1.53-.561.104-1.272.189-3.712-.789-3.13-1.306-5.141-4.518-5.296-4.728-.154-.21-1.262-1.68-1.262-3.198 0-1.517.803-2.263 1.087-2.577.283-.314.627-.393.836-.393.209 0 .418.001.601.011.195.01.459-.074.718.544.271.64.922 2.219.992 2.378.08.16.14.345.029.555-.099.196-.148.315-.29.484-.15.175-.313.394-.447.53-.15.152-.307.318-.132.625.175.308.777 1.277 1.667 2.07 1.148 1.025 2.113 1.345 2.421 1.501.308.156.487.131.669-.08.21-.236.896-1.04 1.135-1.397.239-.356.478-.307.803-.175.325.133 2.07.976 2.423 1.154.355.178.593.267.682.419.092.156.092.899-.18 1.647z"></path>
</svg>
</span></div></a>
              
                <a href="https://m.me/AboSanad.Esa"><div className="box box3"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.383 3 3 8.372 3 15c0 2.928 1.121 5.613 2.961 7.678V27l2.66-1.448C10.5 26.15 12.74 27 15 27c6.617 0 12-5.372 12-12S21.617 3 15 3zm1.354 18.291l-3.883-4.157-5.893 4.157 6.031-6.457 3.869 4.157 5.88-4.157-6.004 6.457z"></path>
</svg>
</span></div></a>
              
                <div className="box box4"></div>
              </div>
            
        </div>
        <div className="row justify-content-center mt-5">
            <div className="card">
                <div className="background2">
                </div>
                <div className="logo">
                    <img src="./images/logo.png" className="card-img-top" alt="..."/>
                </div>
                <div className="name">
                    <h4 style={{color: "#00254d"}}>Supervisor</h4>
                    <h5 style={{color: "#2E2E2E"}}>SANAD</h5>
                    </div>
               <a href="https://t.me/SND_Supervisor"><div className="box box1"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M 15 3 C 8.371 3 3 8.371 3 15 C 3 21.629 8.371 27 15 27 C 21.629 27 27 21.629 27 15 C 27 8.371 21.629 3 15 3 z M 19.021484 19.021484 L 15.699219 16.839844 L 14.144531 19.267578 C 14.007781 19.502953 13.784625 19.632812 13.541016 19.632812 C 13.409266 19.632812 13.276016 19.598828 13.158203 19.527344 C 12.828453 19.328109 12.715312 18.889234 12.941406 18.583984 L 14.380859 16.529297 L 10.722656 14.982422 C 10.319906 14.818047 10.271516 14.303047 10.628906 14.075195 L 19.255859 9.03125 C 19.570109 8.8264844 19.983984 9.0622812 19.865234 9.421875 L 17.652344 17.033203 C 17.554594 17.349328 17.242094 17.543859 16.914062 17.498047 C 16.862562 17.490797 16.810922 17.482172 16.759766 17.472656 z"></path>
</svg>
</span></div></a>
              
                <a href="https://wa.me/+0201019205620"><div className="box box2"> <span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.373 3 3 8.373 3 15c0 2.554.78 4.92 2.137 6.888L3 27l5.213-2.113C10.074 26.22 12.453 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3zm4.802 17.77c-.271.75-1.353 1.393-2.107 1.53-.561.104-1.272.189-3.712-.789-3.13-1.306-5.141-4.518-5.296-4.728-.154-.21-1.262-1.68-1.262-3.198 0-1.517.803-2.263 1.087-2.577.283-.314.627-.393.836-.393.209 0 .418.001.601.011.195.01.459-.074.718.544.271.64.922 2.219.992 2.378.08.16.14.345.029.555-.099.196-.148.315-.29.484-.15.175-.313.394-.447.53-.15.152-.307.318-.132.625.175.308.777 1.277 1.667 2.07 1.148 1.025 2.113 1.345 2.421 1.501.308.156.487.131.669-.08.21-.236.896-1.04 1.135-1.397.239-.356.478-.307.803-.175.325.133 2.07.976 2.423 1.154.355.178.593.267.682.419.092.156.092.899-.18 1.647z"></path>
</svg>
</span></div></a>
              
                <a href="https://m.me/cm/AbatxCWiesvl0Uj3/?send_source=cm%3Acopy_invite_link"><div className="box box3"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.383 3 3 8.372 3 15c0 2.928 1.121 5.613 2.961 7.678V27l2.66-1.448C10.5 26.15 12.74 27 15 27c6.617 0 12-5.372 12-12S21.617 3 15 3zm1.354 18.291l-3.883-4.157-5.893 4.157 6.031-6.457 3.869 4.157 5.88-4.157-6.004 6.457z"></path>
</svg>
</span></div></a>
              
                <div className="box box4"></div>
              </div>
              <div className="card">
                <div className="background2">
                </div>
                <div className="logo">
                    <img src="./images/logo.png" className="card-img-top" alt="..."/>
                </div>
                <div className="name">
                    <h4 style={{color: "#00254d"}}>Supervisor</h4>
                    <h5 style={{color: "#2E2E2E"}}>SANAD</h5>
                    </div>
               <a href="https://t.me/SND_Supervisor"><div className="box box1"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M 15 3 C 8.371 3 3 8.371 3 15 C 3 21.629 8.371 27 15 27 C 21.629 27 27 21.629 27 15 C 27 8.371 21.629 3 15 3 z M 19.021484 19.021484 L 15.699219 16.839844 L 14.144531 19.267578 C 14.007781 19.502953 13.784625 19.632812 13.541016 19.632812 C 13.409266 19.632812 13.276016 19.598828 13.158203 19.527344 C 12.828453 19.328109 12.715312 18.889234 12.941406 18.583984 L 14.380859 16.529297 L 10.722656 14.982422 C 10.319906 14.818047 10.271516 14.303047 10.628906 14.075195 L 19.255859 9.03125 C 19.570109 8.8264844 19.983984 9.0622812 19.865234 9.421875 L 17.652344 17.033203 C 17.554594 17.349328 17.242094 17.543859 16.914062 17.498047 C 16.862562 17.490797 16.810922 17.482172 16.759766 17.472656 z"></path>
</svg>
</span></div></a>
              
                <a href="https://wa.me/+0201019205620"><div className="box box2"> <span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.373 3 3 8.373 3 15c0 2.554.78 4.92 2.137 6.888L3 27l5.213-2.113C10.074 26.22 12.453 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3zm4.802 17.77c-.271.75-1.353 1.393-2.107 1.53-.561.104-1.272.189-3.712-.789-3.13-1.306-5.141-4.518-5.296-4.728-.154-.21-1.262-1.68-1.262-3.198 0-1.517.803-2.263 1.087-2.577.283-.314.627-.393.836-.393.209 0 .418.001.601.011.195.01.459-.074.718.544.271.64.922 2.219.992 2.378.08.16.14.345.029.555-.099.196-.148.315-.29.484-.15.175-.313.394-.447.53-.15.152-.307.318-.132.625.175.308.777 1.277 1.667 2.07 1.148 1.025 2.113 1.345 2.421 1.501.308.156.487.131.669-.08.21-.236.896-1.04 1.135-1.397.239-.356.478-.307.803-.175.325.133 2.07.976 2.423 1.154.355.178.593.267.682.419.092.156.092.899-.18 1.647z"></path>
</svg>
</span></div></a>
              
                <a href="https://m.me/cm/AbatxCWiesvl0Uj3/?send_source=cm%3Acopy_invite_link"><div className="box box3"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.383 3 3 8.372 3 15c0 2.928 1.121 5.613 2.961 7.678V27l2.66-1.448C10.5 26.15 12.74 27 15 27c6.617 0 12-5.372 12-12S21.617 3 15 3zm1.354 18.291l-3.883-4.157-5.893 4.157 6.031-6.457 3.869 4.157 5.88-4.157-6.004 6.457z"></path>
</svg>
</span></div></a>
              
                <div className="box box4"></div>
              </div>
        </div>
        <div className="row justify-content-center mt-5">
            <div className="card">
                <div className="background3">
                </div>
                <div className="logo2">
                    <img src="./images/logo-02.png" className="card-img-top adminnn" alt="..."/>
                </div>
                <div className="name2">
                    <h3 style={{color: "rgb(212, 212, 212)"}}>Admin</h3>
                    <h5></h5>
                    </div>
               <a href="#"><div className="box box1"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M 15 3 C 8.371 3 3 8.371 3 15 C 3 21.629 8.371 27 15 27 C 21.629 27 27 21.629 27 15 C 27 8.371 21.629 3 15 3 z M 19.021484 19.021484 L 15.699219 16.839844 L 14.144531 19.267578 C 14.007781 19.502953 13.784625 19.632812 13.541016 19.632812 C 13.409266 19.632812 13.276016 19.598828 13.158203 19.527344 C 12.828453 19.328109 12.715312 18.889234 12.941406 18.583984 L 14.380859 16.529297 L 10.722656 14.982422 C 10.319906 14.818047 10.271516 14.303047 10.628906 14.075195 L 19.255859 9.03125 C 19.570109 8.8264844 19.983984 9.0622812 19.865234 9.421875 L 17.652344 17.033203 C 17.554594 17.349328 17.242094 17.543859 16.914062 17.498047 C 16.862562 17.490797 16.810922 17.482172 16.759766 17.472656 z"></path>
</svg>
</span></div></a>
              
                <a href="##"><div className="box box2"> <span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.373 3 3 8.373 3 15c0 2.554.78 4.92 2.137 6.888L3 27l5.213-2.113C10.074 26.22 12.453 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3zm4.802 17.77c-.271.75-1.353 1.393-2.107 1.53-.561.104-1.272.189-3.712-.789-3.13-1.306-5.141-4.518-5.296-4.728-.154-.21-1.262-1.68-1.262-3.198 0-1.517.803-2.263 1.087-2.577.283-.314.627-.393.836-.393.209 0 .418.001.601.011.195.01.459-.074.718.544.271.64.922 2.219.992 2.378.08.16.14.345.029.555-.099.196-.148.315-.29.484-.15.175-.313.394-.447.53-.15.152-.307.318-.132.625.175.308.777 1.277 1.667 2.07 1.148 1.025 2.113 1.345 2.421 1.501.308.156.487.131.669-.08.21-.236.896-1.04 1.135-1.397.239-.356.478-.307.803-.175.325.133 2.07.976 2.423 1.154.355.178.593.267.682.419.092.156.092.899-.18 1.647z"></path>
</svg>
</span></div></a>
              
                <a href="###"><div className="box box3"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.383 3 3 8.372 3 15c0 2.928 1.121 5.613 2.961 7.678V27l2.66-1.448C10.5 26.15 12.74 27 15 27c6.617 0 12-5.372 12-12S21.617 3 15 3zm1.354 18.291l-3.883-4.157-5.893 4.157 6.031-6.457 3.869 4.157 5.88-4.157-6.004 6.457z"></path>
</svg>
</span></div></a>
              
                <div className="box box4"></div>
              </div>
              <div className="card">
                <div className="background3">
                </div>
                <div className="logo2">
                    <img src="./images/logo-02.png" className="card-img-top adminnn" alt="..."/>
                </div>
                <div className="name2">
                    <h3 style={{color: "rgb(212, 212, 212)"}}>Admin</h3>
                    <h5></h5>
                    </div>
               <a href="#"><div className="box box1"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M 15 3 C 8.371 3 3 8.371 3 15 C 3 21.629 8.371 27 15 27 C 21.629 27 27 21.629 27 15 C 27 8.371 21.629 3 15 3 z M 19.021484 19.021484 L 15.699219 16.839844 L 14.144531 19.267578 C 14.007781 19.502953 13.784625 19.632812 13.541016 19.632812 C 13.409266 19.632812 13.276016 19.598828 13.158203 19.527344 C 12.828453 19.328109 12.715312 18.889234 12.941406 18.583984 L 14.380859 16.529297 L 10.722656 14.982422 C 10.319906 14.818047 10.271516 14.303047 10.628906 14.075195 L 19.255859 9.03125 C 19.570109 8.8264844 19.983984 9.0622812 19.865234 9.421875 L 17.652344 17.033203 C 17.554594 17.349328 17.242094 17.543859 16.914062 17.498047 C 16.862562 17.490797 16.810922 17.482172 16.759766 17.472656 z"></path>
</svg>
</span></div></a>
              
                <a href="##"><div className="box box2"> <span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.373 3 3 8.373 3 15c0 2.554.78 4.92 2.137 6.888L3 27l5.213-2.113C10.074 26.22 12.453 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3zm4.802 17.77c-.271.75-1.353 1.393-2.107 1.53-.561.104-1.272.189-3.712-.789-3.13-1.306-5.141-4.518-5.296-4.728-.154-.21-1.262-1.68-1.262-3.198 0-1.517.803-2.263 1.087-2.577.283-.314.627-.393.836-.393.209 0 .418.001.601.011.195.01.459-.074.718.544.271.64.922 2.219.992 2.378.08.16.14.345.029.555-.099.196-.148.315-.29.484-.15.175-.313.394-.447.53-.15.152-.307.318-.132.625.175.308.777 1.277 1.667 2.07 1.148 1.025 2.113 1.345 2.421 1.501.308.156.487.131.669-.08.21-.236.896-1.04 1.135-1.397.239-.356.478-.307.803-.175.325.133 2.07.976 2.423 1.154.355.178.593.267.682.419.092.156.092.899-.18 1.647z"></path>
</svg>
</span></div></a>
              
                <a href="###"><div className="box box3"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.383 3 3 8.372 3 15c0 2.928 1.121 5.613 2.961 7.678V27l2.66-1.448C10.5 26.15 12.74 27 15 27c6.617 0 12-5.372 12-12S21.617 3 15 3zm1.354 18.291l-3.883-4.157-5.893 4.157 6.031-6.457 3.869 4.157 5.88-4.157-6.004 6.457z"></path>
</svg>
</span></div></a>
              
                <div className="box box4"></div>
              </div>
              <div className="card">
                <div className="background3">
                </div>
                <div className="logo2">
                    <img src="./images/logo-02.png" className="card-img-top adminnn" alt="..."/>
                </div>
                <div className="name2">
                    <h3 style={{color: "rgb(212, 212, 212)"}}>Admin</h3>
                    <h5></h5>
                    </div>
               <a href="#"><div className="box box1"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M 15 3 C 8.371 3 3 8.371 3 15 C 3 21.629 8.371 27 15 27 C 21.629 27 27 21.629 27 15 C 27 8.371 21.629 3 15 3 z M 19.021484 19.021484 L 15.699219 16.839844 L 14.144531 19.267578 C 14.007781 19.502953 13.784625 19.632812 13.541016 19.632812 C 13.409266 19.632812 13.276016 19.598828 13.158203 19.527344 C 12.828453 19.328109 12.715312 18.889234 12.941406 18.583984 L 14.380859 16.529297 L 10.722656 14.982422 C 10.319906 14.818047 10.271516 14.303047 10.628906 14.075195 L 19.255859 9.03125 C 19.570109 8.8264844 19.983984 9.0622812 19.865234 9.421875 L 17.652344 17.033203 C 17.554594 17.349328 17.242094 17.543859 16.914062 17.498047 C 16.862562 17.490797 16.810922 17.482172 16.759766 17.472656 z"></path>
</svg>
</span></div></a>
              
                <a href="##"><div className="box box2"> <span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.373 3 3 8.373 3 15c0 2.554.78 4.92 2.137 6.888L3 27l5.213-2.113C10.074 26.22 12.453 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3zm4.802 17.77c-.271.75-1.353 1.393-2.107 1.53-.561.104-1.272.189-3.712-.789-3.13-1.306-5.141-4.518-5.296-4.728-.154-.21-1.262-1.68-1.262-3.198 0-1.517.803-2.263 1.087-2.577.283-.314.627-.393.836-.393.209 0 .418.001.601.011.195.01.459-.074.718.544.271.64.922 2.219.992 2.378.08.16.14.345.029.555-.099.196-.148.315-.29.484-.15.175-.313.394-.447.53-.15.152-.307.318-.132.625.175.308.777 1.277 1.667 2.07 1.148 1.025 2.113 1.345 2.421 1.501.308.156.487.131.669-.08.21-.236.896-1.04 1.135-1.397.239-.356.478-.307.803-.175.325.133 2.07.976 2.423 1.154.355.178.593.267.682.419.092.156.092.899-.18 1.647z"></path>
</svg>
</span></div></a>
              
                <a href="###"><div className="box box3"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.383 3 3 8.372 3 15c0 2.928 1.121 5.613 2.961 7.678V27l2.66-1.448C10.5 26.15 12.74 27 15 27c6.617 0 12-5.372 12-12S21.617 3 15 3zm1.354 18.291l-3.883-4.157-5.893 4.157 6.031-6.457 3.869 4.157 5.88-4.157-6.004 6.457z"></path>
</svg>
</span></div></a>
              
                <div className="box box4"></div>
              </div>
              <div className="card">
                <div className="background3">
                </div>
                <div className="logo2">
                    <img src="./images/logo-02.png" className="card-img-top adminnn" alt="..."/>
              
                </div>
                <div className="name2">
                <h3 style={{color: "rgb(212, 212, 212)"}}>Admin</h3>
                <h5></h5>
                </div>
              
               <a href="#"><div className="box box1"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M 15 3 C 8.371 3 3 8.371 3 15 C 3 21.629 8.371 27 15 27 C 21.629 27 27 21.629 27 15 C 27 8.371 21.629 3 15 3 z M 19.021484 19.021484 L 15.699219 16.839844 L 14.144531 19.267578 C 14.007781 19.502953 13.784625 19.632812 13.541016 19.632812 C 13.409266 19.632812 13.276016 19.598828 13.158203 19.527344 C 12.828453 19.328109 12.715312 18.889234 12.941406 18.583984 L 14.380859 16.529297 L 10.722656 14.982422 C 10.319906 14.818047 10.271516 14.303047 10.628906 14.075195 L 19.255859 9.03125 C 19.570109 8.8264844 19.983984 9.0622812 19.865234 9.421875 L 17.652344 17.033203 C 17.554594 17.349328 17.242094 17.543859 16.914062 17.498047 C 16.862562 17.490797 16.810922 17.482172 16.759766 17.472656 z"></path>
</svg>
</span></div></a>
              
                <a href="##"><div className="box box2"> <span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.373 3 3 8.373 3 15c0 2.554.78 4.92 2.137 6.888L3 27l5.213-2.113C10.074 26.22 12.453 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3zm4.802 17.77c-.271.75-1.353 1.393-2.107 1.53-.561.104-1.272.189-3.712-.789-3.13-1.306-5.141-4.518-5.296-4.728-.154-.21-1.262-1.68-1.262-3.198 0-1.517.803-2.263 1.087-2.577.283-.314.627-.393.836-.393.209 0 .418.001.601.011.195.01.459-.074.718.544.271.64.922 2.219.992 2.378.08.16.14.345.029.555-.099.196-.148.315-.29.484-.15.175-.313.394-.447.53-.15.152-.307.318-.132.625.175.308.777 1.277 1.667 2.07 1.148 1.025 2.113 1.345 2.421 1.501.308.156.487.131.669-.08.21-.236.896-1.04 1.135-1.397.239-.356.478-.307.803-.175.325.133 2.07.976 2.423 1.154.355.178.593.267.682.419.092.156.092.899-.18 1.647z"></path>
</svg>
</span></div></a>
              
                <a href="###"><div className="box box3"><span className= "icon2"><svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className="svg">
  <path d="M15 3C8.383 3 3 8.372 3 15c0 2.928 1.121 5.613 2.961 7.678V27l2.66-1.448C10.5 26.15 12.74 27 15 27c6.617 0 12-5.372 12-12S21.617 3 15 3zm1.354 18.291l-3.883-4.157-5.893 4.157 6.031-6.457 3.869 4.157 5.88-4.157-6.004 6.457z"></path>
</svg>
</span></div></a>
              
                <div className="box box4"></div>
              </div>
        </div>
    </div>

    </div>
  )
}

export default ContactUs