import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Profile.css";
import SyncLoader from "react-spinners/SyncLoader"; // استيراد الـ Spinner
import { useTranslation } from "react-i18next";
const Profile = () => {
  const { t } = useTranslation("profile"); // تحديد Namespace
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    id_person: "",
    identity: "",
    phone: "",
    frontId: null,
    backId: null,
    profilePicture: null,
  });

  const [errors, setErrors] = useState({});
  const [statusAccept, setStatusAccept] = useState(null);
  const [complete_profile, setCompleteProfile] = useState(null);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true); // حالة التحميل تبدأ بـ true
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const yourToken = localStorage.getItem("token");
    setIsLoggedIn(!!yourToken);

    if (yourToken) {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(
            "http://sanaddigital.com/public/api/profile",
            {
              headers: { Authorization: `Bearer ${yourToken}` },
            }
          );
          const { userDetails } = response.data;
          setFormData({
            fullName: userDetails.name || "",
            email: userDetails.email || "",
            id_person: userDetails.id_person || "",
            identity: userDetails.identity || "",
            phone: userDetails.phone || "",
            reject_reason: userDetails.reject_reason || "",
            frontId: userDetails.upload_front_id
              ? `http://sanaddigital.com/storage/app/public/${userDetails.upload_front_id}`
              : null,
            backId: userDetails.upload_back_id
              ? `http://sanaddigital.com/storage/app/public/${userDetails.upload_back_id}`
              : null,
            profilePicture: userDetails.image
              ? `http://sanaddigital.com/storage/app/public/${userDetails.image}`
              : null,
          });
          setStatusAccept(userDetails.status_accept || null);
          setCompleteProfile(userDetails.complete_profile || null);
        } catch (error) {
          setNotifications((prev) => [...prev, "Error fetching profile data"]);
          
        } finally {
          setLoading(false); // إيقاف حالة التحميل بعد إكمال العملية
        }
      };

      fetchProfile();
    } else {
      setLoading(false); // إيقاف التحميل إذا لم يكن المستخدم مسجلًا
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    if (!isLoggedIn) return; // تجاهل التحديثات إذا لم يكن المستخدم مسجلًا
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });

    if (id === "email") {
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailPattern.test(value)) {
        errors.email = "Please enter a valid email address in English only.";
      } else {
        delete errors.email;
      }
    }

    if (id === "phone") {
      const phonePattern = /^\d{0,20}$/;
      if (!phonePattern.test(value)) {
        errors.phone =
          "Phone number must contain only numbers and not exceed 20 digits.";
      } else {
        delete errors.phone;
      }
    }
  };

 const handleFileChange = (event) => {
    const { id, files } = event.target;
    if (files && files[0]) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [`${id}Preview`]: URL.createObjectURL(files[0]), // رابط مؤقت للعرض
            [id]: files[0], // الملف الأصلي للإرسال
        }));
    }
};

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    const yourToken = localStorage.getItem("token"); // جلب التوكن من localStorage

    try {
      const response = await axios.post(
        "http://sanaddigital.com/public/api/editProfileUser",
        {
          email: formData.email,
          phone: formData.phone,
        },
        {
          headers: {
            Authorization: `Bearer ${yourToken}`, // إضافة التوكن للتوثيق
            "Content-Type": "application/json",
          },
        }
      );

      setErrors({});
      setIsEditingEmail(false);
      setIsEditingPhone(false);
    } catch (error) {
      if (error.response?.status === 401) {
      } else if (error.response?.status === 422) {
        // عرض الأخطاء الناتجة عن التحقق
        const errorMessages = error.response.data.errors.reduce((acc, curr) => {
          acc[curr.field] = curr.message;
          return acc;
        }, {});
        setErrors(errorMessages);
      } else {
      }
    }
    // إضافة الرسالة بعد التحديث الناجح
    setNotifications((prev) => [...prev, "Profile updated successfully!"]);

    // إزالة الرسالة بعد 3 ثوانٍ
    setTimeout(() => {
      setNotifications((prev) => prev.slice(1)); // إزالة الرسالة الأولى من المصفوفة
    }, 3000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("fullName", formData.fullName);
    data.append("email", formData.email);
    data.append("identity", formData.identity);
    data.append("phone", formData.phone);
    data.append("frontId", formData.frontId);
    data.append("backId", formData.backId);
    data.append("profilePicture", formData.profilePicture);

    const yourToken = localStorage.getItem("token");

    axios
      .post("http://sanaddigital.com/public/api/profile/update", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${yourToken}`,
        },
      })
      .then((response) => {
        setNotifications((prev) => [...prev, "Profile updated successfully!"]);

        // إزالة الرسالة بعد 3 ثوانٍ
        setTimeout(() => {
          setNotifications((prev) => prev.slice(1)); // إزالة الرسالة الأولى من المصفوفة
        }, 3000);
        setErrors({});
        setCompleteProfile(1);
      })
      .catch((error) => {
        console.error(
          "Error updating profile:",
          error.response?.data?.message || error.message
        );
        setErrors(error.response?.data?.errors || {});
      });
  };
  const copyID = () => {
    const id_person = formData.id_person;
    navigator.clipboard
      .writeText(id_person)
      .then(() => {
        setNotifications((prev) => [...prev, "ID copied to clipboard"]);
      })
      .catch((err) => {
        setNotifications("Could not copy text: ", err);
      });
    setTimeout(() => {
      setNotifications("");
    }, 3000);
  };

  const handleProfilePictureClick = () => {
    document.getElementById("profilePicture").click();
  };
  const handleEditEmail = () => setIsEditingEmail(true);
  const handleEditPhone = () => setIsEditingPhone(true);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <div className="loader-container">
            <SyncLoader color="#36d7b7" loading={loading} size={30} />
          </div>
        ) : (
          <div className="profile-container">
            {!isLoggedIn ? (
              <div>
                <div dir={t("direction")} className="profile-header">
                  <div className="profile-picture">
                    <img src={"/images/prof.jpg"} alt={t("profile_picture")} />
                  </div>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: "orange",
                    }}
                    to="/SignUp"
                    className="profile-status status-under-review"
                    id="status"
                  >
                    <span>{t("sign_up_first")}</span>
                  </Link>
                </div>
                <form dir={t("direction")} id="profile-form" noValidate>
                  <div className="form-group">
                    <label dir={t("direction")} htmlFor="fullName">
                      {t("full_name")}
                    </label>
                    <input
                      type="text"
                      id="rrrrr"
                      className="input-field"
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label dir={t("direction")} htmlFor="email">
                      {t("email")}
                    </label>
                    <input
                      type="email"
                      id="rrrrr"
                      className="input-field"
                      readOnly
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label dir={t("direction")} htmlFor="id_person">
                      {t("id_number")}
                    </label>
                    <input
                      type="text"
                      id="rrrr"
                      className="input-field"
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label dir={t("direction")} htmlFor="identity">
                      {t("national_id_passport")}
                    </label>
                    <input
                      type="text"
                      id="rrrrrr"
                      className="input-field"
                      readOnly
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label dir={t("direction")} htmlFor="phone">
                      {t("whatsapp_number")}
                    </label>
                    <input
                      type="text"
                      id="rrrr"
                      className="input-field"
                      readOnly
                      required
                    />
                  </div>

                  <div className="form-group upload-fields">
                    <div>
                      <label dir={t("direction")} htmlFor="frontId">
                        {t("front_id")}
                      </label>
                      <input
                        type="file"
                        id="rrrr"
                        className="input-field"
                        readOnly
                        accept="image/*"
                        required
                      />
                    </div>
                    <div>
                      <label dir={t("direction")} htmlFor="backId">
                        {t("back_id")}
                      </label>
                      <input
                        type="file"
                        id="rrrr"
                        className="input-field"
                        readOnly
                        accept="image/*"
                        required
                      />
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div>
                {statusAccept === 1 && complete_profile === 1 && (
                  <div>
                    <div dir={t("direction")} className="profile-header">
                      <div className="profile-picture">
                        <img
                          src={formData.profilePicture || "/images/prof.jpg"}
                          alt={t("profile_picture")}
                        />
                        <div dir={t("direction")} className="profile-id">
                          <p>
                            {formData.id_person}{" "}
                            <span className="copy-icon" onClick={copyID}>
                              📋
                            </span>
                          </p>
                        </div>
                      </div>
                      <div
                        className="profile-status status-verified"
                        id="status"
                      >
                        <span style={{ color: "white" }}>{t("verified")}</span>
                      </div>
                    </div>
                    <form id="profile-form" onSubmit={handleSubmit2} noValidate>
                      <div>
                        <div className="form-group">
                          <label dir={t("direction")} htmlFor="fullName">
                            {t("full_name")}
                          </label>
                          <input
                            type="text"
                            id="fullName"
                            readOnly
                            className="input-field"
                            value={formData.fullName}
                          />
                          {errors.fullName && (
                            <span className="error-message">
                              {errors.fullName}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label dir={t("direction")} htmlFor="email">
                            {t("email")}
                          </label>
                          <div className="input-wrapper">
                            <input
                              type="email"
                              id="email"
                              className="input-field"
                              value={formData.email}
                              onChange={handleInputChange}
                              readOnly={!isEditingEmail}
                            />
                            <span
                              className="edit-icon"
                              onClick={handleEditEmail}
                            >
                              ✏️
                            </span>
                          </div>
                          {errors.email && (
                            <span className="error-message">
                              {errors.email}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label dir={t("direction")} htmlFor="id_person">
                            {t("id_number")}
                          </label>
                          <input
                            type="text"
                            id="id_person"
                            readOnly
                            className="input-field"
                            value={formData.id_person}
                          />
                          {errors.id_person && (
                            <span className="error-message">
                              {errors.id_person}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label dir={t("direction")} htmlFor="identity">
                            {t("national_id_passport")}
                          </label>
                          <input
                            type="text"
                            id="identity"
                            readOnly
                            className="input-field"
                            value={formData.identity}
                          />
                          {errors.identity && (
                            <span className="error-message">
                              {errors.identity}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label dir={t("direction")} htmlFor="phone">
                            {t("whatsapp_number")}
                          </label>
                          <div className="input-wrapper">
                            <input
                              type="text"
                              id="phone"
                              className="input-field"
                              value={formData.phone}
                              onChange={handleInputChange}
                              readOnly={!isEditingPhone}
                            />
                            <span
                              className="edit-icon"
                              onClick={handleEditPhone}
                            >
                              ✏️
                            </span>
                          </div>
                          {errors.phone && (
                            <span className="error-message">
                              {errors.phone}
                            </span>
                          )}
                        </div>

                        <div
                          dir={t("direction")}
                          className="form-group upload-fields"
                        >
                          <div>
                            <label dir={t("direction")} htmlFor="frontId">
                              {t("front_id")}
                            </label>
                            {formData.frontId && (
                              <img
                                src={formData.frontId}
                                alt={t("front_id")}
                                className="uploaded-image"
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                          <div>
                            <label dir={t("direction")} htmlFor="backId">
                              {t("back_id")}
                            </label>
                            {formData.backId && (
                              <img
                                src={formData.backId}
                                alt={t("back_id")}
                                className="uploaded-image"
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <button
                        style={{ textAlign: "center" }}
                        type="submit"
                        className="save-button2"
                      >
                        {t("save")}
                      </button>
                    </form>
                  </div>
                )}
                {complete_profile !== 1 && statusAccept !== 1 && (
                  <div>
                    <div dir={t("direction")} className="profile-header">
                      <div
                        className="profile-picture"
                        onClick={handleProfilePictureClick}
                      >
                     <img
        src={formData.profilePicturePreview || "/images/prof.jpg"}
        alt={t("profile_picture")}
        className="uploaded-image"
        style={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            objectFit: 'cover',
            
        }}
    />
                        <div className="add-photo-icon">
                          <i className="fa fa-camera"></i>
                        </div>
                        <input
                          type="file"
                          id="profilePicture"
                          className="input-field"
                          onChange={handleFileChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          required
                        />
                      </div>

                      <div
                        className="profile-status status-under-review"
                        id="status"
                      >
                        <span>{t("complete_profile")}</span>
                      </div>
                    </div>

                    <div dir={t("direction")} className="profile-id">
                      <p>
                        {formData.id_person}{" "}
                        <span className="copy-icon" onClick={copyID}>
                          📋
                        </span>
                      </p>
                    </div>

                    <p style={{ color: "red" }}>*{formData.reject_reason}</p>

                    <form id="profile-form" onSubmit={handleSubmit} noValidate>
                      <div className="form-group">
                        <label dir={t("direction")} htmlFor="fullName">
                          {t("full_name")}
                        </label>
                        <input
                          type="text"
                          id="fullName"
                          className="input-field"
                          value={formData.fullName}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.fullName && (
                          <span className="error-message">
                            {errors.fullName}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <label dir={t("direction")} htmlFor="email">
                          {t("email")}
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="input-field"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.email && (
                          <span className="error-message">{errors.email}</span>
                        )}
                      </div>

                      <div className="form-group">
                        <label dir={t("direction")} htmlFor="id_person">
                          {t("id_number")}
                        </label>
                        <input
                          type="text"
                          id="id_person"
                          className="input-field"
                          value={formData.id_person}
                          readOnly
                        />
                        {errors.id_person && (
                          <span className="error-message">
                            {errors.id_person}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <label dir={t("direction")} htmlFor="identity">
                          {t("national_id_passport")}
                        </label>
                        <input
                          type="text"
                          id="identity"
                          className="input-field"
                          value={formData.identity}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.identity && (
                          <span className="error-message">
                            {errors.identity}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <label dir={t("direction")} htmlFor="phone">
                          {t("whatsapp_number")}
                        </label>
                        <input
                          type="text"
                          id="phone"
                          className="input-field"
                          value={formData.phone}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.phone && (
                          <span className="error-message">{errors.phone}</span>
                        )}
                      </div>

                      <div
                        dir={t("direction")}
                        className="form-group upload-fields"
                      >
                        <div dir={t("direction")}>
                          <label dir={t("direction")} htmlFor="frontId">
                            {t("front_id")}
                          </label>
                          {formData.frontIdPreview && (
                            <img
                              src={formData.frontIdPreview}
                              alt="Front ID"
                              className="uploaded-image"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <input
                            type="file"
                            id="frontId"
                            className="input-field"
                            onChange={handleFileChange}
                            accept="image/*"
                            required
                          />
                        </div>
                        <div dir={t("direction")}>
                          <label dir={t("direction")} htmlFor="backId">
                            {t("back_id")}
                          </label>
                          {formData.backIdPreview && (
            <img
                src={formData.backIdPreview}
                alt="Back ID"
                className="uploaded-image"
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
        )}
                          <input
                            type="file"
                            id="backId"
                            className="input-field"
                            onChange={handleFileChange}
                            accept="image/*"
                            required
                          />
                        </div>
                      </div>

                      <button type="submit" className="save-button2">
                        {t("save")}
                      </button>
                    </form>
                  </div>
                )}

                {statusAccept !== 1 && complete_profile === 1 && (
                  <div className="review-page">
                    <div className="logo-container">
                      <img
                        src="/images/logo.png" // Replace this path with your actual logo
                        alt="Logo"
                        className="review-logo"
                      />
                    </div>
                    <div className="review-box">
                      <p className="welcome-text">{t("welcome_text")}</p>
                      <p className="team-name">{t("team_name")}</p>
                      <p className="thanks-text">{t("thanks_text")}</p>
                      <p className="review-message">{t("review_message")}</p>
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://wa.me/+201004208289"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="owner-button"
                      >
                        {t("owner_button_text")}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <style>
        {`
                    .status-message {
                        text-align: center;
                        margin-top: 20px;
                        font-size: 18px;
                        color: #333;
                    }
                `}
      </style>
      {notifications.length > 0 && (
        <div className="notification-container">
          {notifications.map((msg, index) => (
            <div key={index} className="notification-message">
              {msg}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Profile;
