import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SignInSanadDashboard.css';

const SignInSanadDashboard = () => {
  const [email, setEmail] = useState('');
  const [id_person, setIdPerson] = useState(''); // تعديل اسم المتغير ليطابق backend
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (isBlocked) {
      setError('You have been blocked due to too many login attempts. Please try again later.');
      return;
    }

    try {
      const response = await axios.post('http://sanaddigital.com/public/api/DashboardLogin', {
        email,
        password,
        id_person, // استخدام id_person حسب الجهة الخلفية
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('token', token);
        navigate('/Dashboard');
      }
    } catch (err) {
      console.error('Error details:', err);
      setAttempts(prevAttempts => {
        const newAttempts = prevAttempts + 1;
        
        if (newAttempts >= 5) {
          setIsBlocked(true);
          setError('You have been blocked due to too many login attempts. Please try again later.');
        }
        
        return newAttempts;
      });

      if (err.response) {
        const errorMessage = err.response.data.errorMessage || err.response.data.message || 'Invalid credentials. Please check your email, password, or ID.';
        setError(errorMessage);
      } else if (err.request) {
        setError('No response received from the server. Please check your network connection.');
      } else {
        setError(`An error occurred: ${err.message}`);
      }
    }
  };

  return (
    <div style={{marginTop: '-65px'}} className="signin-container">
      <div className="signin-card">
        <h2>Sign In</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="id_person">Code</label>
            <input
              type="password"
              id="id_person"
              value={id_person}
              onChange={(e) => setIdPerson(e.target.value)} // تعديل اسم الدالة ليطابق المتغير
              required
            />
          </div>
          <button type="submit" className="signin-button" disabled={isBlocked}>Sign In</button>
        </form>
      </div>
    </div>
  );
};

export default SignInSanadDashboard;
