import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios'; // تأكد من إضافة هذا السطر
import './SanadMembers.css';

const goBack = () => {
  window.history.back(); // للرجوع إلى الصفحة السابقة
};

const SanadMembers = () => {
  const navigate = useNavigate(); // تعريف navigate
  const [loading, setLoading] = useState(true); // حالة التحميل

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول إذا لم يكن هناك توكن
      return;
    }

    const checkAdmin = async () => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/checkAdmin', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.data.is_admin) {
          navigate('/404'); // توجيه المستخدم غير الأدمين إلى صفحة 404
        } else {
          setLoading(false); // إذا كان أدمين، قم بتحديث حالة التحميل
        }
      } catch (error) {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه إلى صفحة 404 في حال حدوث خطأ
      }
    };

    checkAdmin(); // التحقق من حالة الأدمين
  }, [navigate]);

  if (loading) {
    return null; // لا تظهر أي شيء أثناء التحميل
  }

  return (
    <div style={{marginTop: '-65px'}} className='SanadMembers'>
      <div style={{ zIndex: "10", marginBottom: '10px' }} className="back-arrow" onClick={goBack}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <div className="sanad-members-page">
        <Link to='/SignUpMembers' style={{ textDecoration: "none", color: "white" }} className="member-card55 red">
          <i className="fas fa-user-plus"></i>
          <h3>Sign up Members</h3>
        </Link>

        <Link to='/UnderreviewMembers' style={{ textDecoration: "none", color: "white" }} className="member-card55 yellow">
          <i className="fas fa-user-clock"></i>
          <h3>Under review Members</h3>
        </Link>

        <Link to='/VerifiedMembers' style={{ textDecoration: "none", color: "white" }} className="member-card55 green">
          <i className="fas fa-user-check"></i>
          <h3>Verified Members</h3>
        </Link>
      </div>
    </div>
  );
};

export default SanadMembers;
