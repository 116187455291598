import React, { useState } from 'react';
import i18next from 'i18next';
import './LanguageSwitcher.css'; // ملف CSS الخاص بالمكون

const LanguageSwitcher = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const changeLanguage = (language) => {
    i18next.changeLanguage(language);
    localStorage.setItem('language', language); // تخزين اللغة في localStorage
    setDropdownOpen(false); // إغلاق القائمة عند اختيار اللغة
  };

  const currentLanguage = i18next.language;

  return (
    <div className="language-switcher">
    <button
      className="toggle-language"
      onClick={() => changeLanguage(currentLanguage === 'en' ? 'ar' : 'en')}
    >
      {currentLanguage === 'en' ? 'AR' : 'EN'}
    </button>
  </div>
  );
};

export default LanguageSwitcher;
