import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './UserProfile.css';
import axios from 'axios';
const UserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // حالة للتحقق من صلاحيات المسؤول

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // توجيه المستخدم لصفحة تسجيل الدخول
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404'); // توجيه المستخدم العادي لصفحة أخرى
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404'); // إعادة التوجيه في حال حدوث خطأ
      });
    }
  }, [navigate]);
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`http://sanaddigital.com/public/api/viewMemberProfile/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error: ${errorData.message || response.statusText}`);
        }
        
        const data = await response.json();
        setProfile(data.userProfile);
        setIsAdmin(data.isAdmin); // تعيين حالة المسؤول

        // التحقق من صلاحيات المستخدم
       

      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError(error.message || 'Error fetching user profile');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchUserProfile();
    } else {
      setError('Invalid User ID');
      setLoading(false);
    }
  }, [id]);

  const openImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  const toggleRejectForm = () => {
    setShowRejectForm(prevState => !prevState); // عكس الحالة الحالية عند الضغط على الزر
  };
  

  const goBack = () => {
    navigate(-1); // العودة إلى الصفحة السابقة
  };

  const handleApprove = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`http://sanaddigital.com/public/api/approveMemberProfile/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message || response.statusText}`);
      }

      const data = await response.json();
      alert(data.success || 'User Approved Successfully');
    } catch (error) {
      console.error('Error approving user profile:', error);
      alert(error.message || 'Error approving user profile');
    }
  };

  const openRejectForm = () => {
    setShowRejectForm(true);
  };

  const closeRejectForm = () => {
    setShowRejectForm(false);
    setRejectReason(''); // Reset reason when closing the form
  };

  const handleReject = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`http://sanaddigital.com/public/api/rejectMemberProfile/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reason: rejectReason }), // Send reason in body
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message || response.statusText}`);
      }

      const data = await response.json();
      alert(data.success || 'User Rejected Successfully');
      closeRejectForm(); // Close the form after success
    } catch (error) {
      console.error('Error rejecting user profile:', error);
      alert(error.message || 'Error rejecting user profile');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!profile) {
    return <div>User not found</div>;
  }

  const baseUrl = 'http://sanaddigital.com/storage/app/public/';

  return (
    <div style={{marginTop: '-65px'}} className="user-profile-container">
      <div className="back-arrow" onClick={goBack}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <div className="user-profile-card">
        <div className="user-profile-picture">
          <img 
            src={`${baseUrl}${profile.image}`} 
            alt="Profile" 
          />
        </div>
        <div className="user-profile-details">
          <p><strong>Name:</strong> {profile.name || 'N/A'}</p>
          <p><strong>ID:</strong> {profile.id}</p>
          <p><strong>Email:</strong> {profile.email || 'N/A'}</p>
          <p><strong>Phone:</strong> {profile.phone || 'N/A'}</p>
          <p><strong>National ID:</strong> {profile.identity || 'N/A'}</p>
        </div>
        <div className="user-id-images">
          <div className="user-id-image">
            <p><strong>Front ID Card:</strong></p>
            <img 
              src={`${baseUrl}${profile.upload_front_id}`} 
              alt="Front ID Card" 
              onClick={() => openImageModal(profile.upload_front_id ? `${baseUrl}${profile.upload_front_id}` : "")} 
            />
          </div>
          <div className="user-id-image">
            <p><strong>Back ID Card:</strong></p>
            <img 
              src={`${baseUrl}${profile.upload_back_id}`} 
              alt="Back ID Card" 
              onClick={() => openImageModal(profile.upload_back_id ? `${baseUrl}${profile.upload_back_id}` : "")} 
            />
          </div>
        </div>
        <div className="user-profile-actions">
          <button className="approve-button" onClick={handleApprove}>Approve</button>
          <button className="reject-button" onClick={toggleRejectForm}>Reject</button>
        </div>
        {showRejectForm && (
          <div className="rejection-comment">
            <input
              type="text"
              placeholder="Write your comment..."
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
            <button className="send-comment" onClick={handleReject}>Send</button>
            
          </div>
        )}
      </div>

      {showImageModal && (
        <div className="custom-modal-overlay" onClick={closeImageModal}>
          <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Enlarged ID" className="custom-modal-image" />
            <button className="custom-modal-close" onClick={closeImageModal}>X</button>
          </div>
        </div>
      )}
 
     
    </div>
  );
};

export default UserProfile;
