import React from 'react'
import "./Footer.css"
function Footer() {
  return (
    <>
    <footer className="footer">

    <div className='Footercen'>
    <img width='75px' src="./images/logo-02.png" alt="logo" />
    <div className='footercen-text'>
      <h3>سندك للمستقبل</h3>
      <h3>Your support for the future</h3>
    </div>
  </div>
  
  
        <div className="footer-bottom" style={{borderTop: '2px solid white', marginBottom: '30px'}}>
          <div className="container-fluid" >
          <div class="row justify-content-center mb-4">
                    <div class="col-lg-9">
                        <div class="single-counterup-01 white">
                                <div class="footercen-text">
                                  <h3>Contact <span style={{fontWeight: 'bold',}}>Sanad Digital</span></h3>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="row justify-content-around align-items-center linsocial">
      {/* Telegram */}
      <div className="col-xl-3 col-lg-3 col-md-6 mb-3 col-6 text-center d-flex align-items-center justify-content-center social-item">
        <a
          href="https://t.me/SanadDigital"
          target="_blank"
          style={{textDecoration: 'none'}}
          rel="noopener noreferrer"
          className="social-link telegram"
        >
          <i className="fab fa-telegram fa-2x"></i>
          <span className="ms-2">Sanad Digital</span>
        </a>
      </div>
      {/* Facebook */}
      <div className="col-xl-3 col-lg-3 col-md-6 mb-3 col-6 text-center d-flex align-items-center justify-content-center social-item">
        <a
          href="https://www.facebook.com/share/186FJ7NYtz/"
          target="_blank"
          style={{textDecoration: 'none'}}
          rel="noopener noreferrer"
          className="social-link facebook"
        >
          <i className="fab fa-facebook fa-2x"></i>
          <span className="ms-2">Sanad Digital</span>
        </a>
      </div>
      {/* Instagram */}
      <div className="col-xl-3 col-lg-3 col-md-6 mb-3 col-6 text-center d-flex align-items-center justify-content-center social-item">
        <a
          href="https://www.instagram.com/ceo_sanaddigital/profilecard/?igsh=MWs2MW5hd3ZtdzR6bA=="
          target="_blank"
          style={{textDecoration: 'none'}}
          rel="noopener noreferrer"
          className="social-link instagram"
        >
          <i className="fab fa-instagram fa-2x"></i>
          <span className="ms-2">Sanad Digital</span>
        </a>
      </div>
      {/* Twitter */}
      <div className="col-xl-3 col-lg-3 col-md-6 mb-3 col-6 text-center d-flex align-items-center justify-content-center social-item">
        <a
          href="https://x.com/sanaddigital?t=qgBG18NfNU8S8u5xEApJmA&s=09"
          target="_blank"
          style={{textDecoration: 'none'}}
          rel="noopener noreferrer"
          className="social-link twitter"
        >
          <i className="fab fa-twitter fa-2x"></i>
          <span className="ms-2">Sanad Digital</span>
        </a>
      </div>
    </div>

  
          </div>
        </div>
  
      </footer>
    </>
  )
}

export default Footer